import { forwardRef } from "react";

import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { SimilarNeighborhoodCard } from "../../cards/similar-neighborhood-card";
import { BaseCarousel } from "../../carousels/base-carousel";
import { MobileBlockHeader } from "../../headers/mobile-block-header";
import { VerticalSectionHeader } from "../../headers/vertical-section-header";

import styles from "./similar-neighborhoods-block.module.scss";
import classNames from "classnames";

export type SimilarNeighborhoodsBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The neighborhood that will be highlighted on the left
     */
    currentNeighborhood?: NeighborhoodDTO;
    /**
     * The neighborhoods that will be on the right
     */
    similarNeighborhoods?: NeighborhoodDTO[];
    /**
     * The function to invoke when the user clicks on the current neighborhood's button
     */
    onCurrentClick?: () => void;
};

export const SimilarNeighborhoodsBlock = forwardRef(
    (
        { className, currentNeighborhood, similarNeighborhoods = [], onCurrentClick }: SimilarNeighborhoodsBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const { screenWidth } = useScreenSize();

        function getLongestAmenityListLengths(): number {
            const lengths = [currentNeighborhood?.amenities?.length ?? 0];
            similarNeighborhoods.forEach((neighborhood) => lengths.push(neighborhood.amenities?.length ?? 0));

            return Math.max(...lengths);
        }

        function getSimilarNeighborhoodsDisplay() {
            // If we have enough space to display all 4 cards at a time
            if (!isMobile) {
                const similarNeighborhoodCardContainerClasses = classNames(styles.similarNeighborhoodCardContainer, styles.static);

                return (
                    <div className={similarNeighborhoodCardContainerClasses}>
                        <SimilarNeighborhoodCard
                            neighborhood={currentNeighborhood}
                            longestAmenityListLength={longestAmenityListLength}
                            onClick={onCurrentClick}
                        />
                        {similarNeighborhoods.map((neighborhood, index) => (
                            <SimilarNeighborhoodCard
                                key={index}
                                neighborhood={neighborhood}
                                longestAmenityListLength={longestAmenityListLength}
                            />
                        ))}
                    </div>
                );
            }

            return (
                <div className={styles.similarNeighborhoodCardContainer}>
                    <div className={styles.stickyCardWrapper}>
                        <SimilarNeighborhoodCard
                            neighborhood={currentNeighborhood}
                            longestAmenityListLength={longestAmenityListLength}
                            onClick={onCurrentClick}
                        />
                    </div>

                    <BaseCarousel className={styles.carousel} dots={true} infinite={false} showOverflow={true} slideGap={16}>
                        {similarNeighborhoods.map((neighborhood, index) => (
                            <SimilarNeighborhoodCard
                                key={index}
                                neighborhood={neighborhood}
                                longestAmenityListLength={longestAmenityListLength}
                            />
                        ))}
                    </BaseCarousel>
                </div>
            );
        }

        function getSectionHeader() {
            const title = "Similar Neighborhoods";

            if (isMobile) {
                return <MobileBlockHeader title={title} />;
            }

            return (
                <VerticalSectionHeader
                    title={title}
                    subtitle="We are currently building in more than 20 of the area's best neighborhoods!"
                />
            );
        }

        const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
        const longestAmenityListLength = getLongestAmenityListLengths();

        const classes = classNames(styles.root, className);

        return (
            <div ref={ref} className={classes}>
                {getSectionHeader()}
                {getSimilarNeighborhoodsDisplay()}
            </div>
        );
    }
);
