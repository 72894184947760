import { ReactNode, useEffect, useState } from "react";

import { Orientation } from "../../../utilities/enums/Orientation";
import { ChevronIcon } from "../../icons/chevron-icon";

import styles from "./expand-panel.module.scss";
import classNames from "classnames";

export type ExpandPanelProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The children you want to be put in the expanded section
     */
    children?: ReactNode;
    /**
     * Whether to use dark text
     */
    isDarkText?: boolean;
    /**
     * Title to display at the top of the panel and when closed
     */
    panelTitle?: string;
    /**
     * The height the panel should be when open
     */
    openHeight?: string;
    /**
     * Whether the panel should start open or not, default is not
     */
    startOpen?: boolean;
    /**
     * event to fire on panel close
     */
    onClose?: () => void;
};

export function ExpandPanel({ className, children, isDarkText, panelTitle, openHeight, startOpen = false, onClose }: ExpandPanelProps) {
    const [isOpen, setOpen] = useState<boolean>(startOpen);

    useEffect(() => setOpen(startOpen), [startOpen]);

    function drawerOnClick() {
        setOpen(!isOpen);
        if (isOpen && onClose) {
            // Wait for animation to get near end to perform on close
            setTimeout(onClose, 700);
        }
    }

    const arrowDirection = isOpen ? Orientation.UP : Orientation.DOWN;

    const style = { height: openHeight && isOpen ? openHeight : "40px" };
    const classes = classNames(styles.root, isDarkText && styles.darkText, className);

    return (
        <div style={style} className={classes}>
            <button className={styles.titleButton} onClick={drawerOnClick} type="button">
                {panelTitle}
                <ChevronIcon className={styles.chevronIcon} arrowDirection={arrowDirection} />
            </button>
            {isOpen && <div className={styles.childWrapper}>{children}</div>}
        </div>
    );
}
