import { ReactNode, useState } from "react";

import { Orientation } from "../../../../utilities/enums/Orientation";
import { ChevronIcon } from "../../../icons/chevron-icon";

import styles from "./filter-expand-section.module.scss";
import classNames from "classnames";

export type FilterExpandSectionProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Children to put in the expanded section
     */
    children?: ReactNode;
    /**
     * Title of the filter expand section
     */
    title?: string;
    /**
     * Whether the section should start expanded
     */
    startOpen?: boolean;
};

export function FilterExpandSection({ className, children, title, startOpen = false }: FilterExpandSectionProps) {
    const [isOpen, setOpen] = useState<boolean>(startOpen);

    const arrowDirection = isOpen ? Orientation.UP : Orientation.DOWN;

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.titleButton} onClick={() => setOpen(!isOpen)}>
                {title}
                <ChevronIcon className={styles.chevron} arrowDirection={arrowDirection} />
            </div>
            {isOpen && children}
        </div>
    );
}
