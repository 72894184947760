import { EntitySearchBlock } from "../../components/blocks/entity-search-block";
import { ReservedModal } from "../../components/modals/reserved-modal";
import { useActiveNeighborhoods } from "../../hooks/data/useActiveNeighborhoods";
import { useFloorPlans } from "../../hooks/data/useFloorPlans";
import { useMarket } from "../../hooks/data/useMarket";
import { useProperties } from "../../hooks/data/useProperties";
import { useSchoolDistricts } from "../../hooks/data/useSchoolDistricts";
import { WizardQueryParameterKeys, WizardQueryParameterValues, useQueryParameters } from "../../hooks/useQueryParameters";
import { useScreenSize } from "../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../utilities/enums/Breakpoints";
import { Page } from "../page";

import styles from "./browse-page.module.scss";

export function BrowsePage() {
    const { parameters } = useQueryParameters();
    const { getSelectedMarket } = useMarket();
    const { screenWidth } = useScreenSize();
    const marketName = getSelectedMarket(true);
    const { activeNeighborhoods, isLoading: areNeighborhoodsLoading } = useActiveNeighborhoods(marketName);
    const { properties, isLoading: arePropertiesLoading } = useProperties(marketName);
    const { schoolDistricts, isLoading: areSchoolDistrictsLoading } = useSchoolDistricts(marketName);
    const { floorPlans, isLoading: areFloorPlansLoading } = useFloorPlans(marketName);

    function getShowFloorPlanCards() {
        return (
            parameters[WizardQueryParameterKeys.BY] === WizardQueryParameterValues.FLOOR_PLANS &&
            !parameters[WizardQueryParameterKeys.FLOOR_PLAN] &&
            !isMobile
        );
    }

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
    const isListLoading = areNeighborhoodsLoading || arePropertiesLoading || areSchoolDistrictsLoading || areFloorPlansLoading;

    return (
        <Page className={styles.root} showFooter={false}>
            <EntitySearchBlock
                className={styles.entitySearchBlock}
                isLoading={isListLoading}
                filterNeighborhoods={true}
                floorPlans={floorPlans}
                neighborhoods={activeNeighborhoods}
                properties={properties}
                schoolDistricts={schoolDistricts}
                showFloorPlanCards={getShowFloorPlanCards()}
            />
            <ReservedModal isAvailableLot={true} />
        </Page>
    );
}
