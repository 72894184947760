import { ReactElement, forwardRef, useImperativeHandle, useMemo, useRef } from "react";

import { APILoadingStatus, Map, useApiLoadingStatus } from "@vis.gl/react-google-maps";

import { LocationDTO, NeighborhoodDTO, PropertyDTO } from "@executivehomes/eh-website-api";
import { SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { Bounds } from "../../../utilities/types/Bounds";
import { Point } from "../../../utilities/types/Point";
import { LoadingSpinner } from "../../misc/loading-spinner";
import { GoogleMapsLegend } from "../google-maps-legend";
import { GoogleMapsOverlay } from "./google-maps-overlay";
import { getGoogleBoundsCoordinates } from "./google-maps-util";

import styles from "./google-maps.module.scss";
import classNames from "classnames";

//#region Default values
const LIGHT_GOOGLE_MAPS_MAP_ID = "70cc15e23b5125f2";
const DARK_GOOGLE_MAPS_MAP_ID = "bf77f6c58484725";
const DEFAULT_CENTER: Point = { lat: 35.77394615795059, lng: -96.15189398485153 };
const DEFAULT_ZOOM = 9;
const DEFAULT_MIN_ZOOM = 5;
const DEFAULT_SETTINGS = {
    // Disables and hides option for the google map to be full screened
    fullscreenControl: false,
    // Disables the control + scroll feature and only requires a scroll on the map
    // gestureHandling: "greedy",
    // Hides the top left buttons for toggling between our map style and satellite images
    mapTypeControl: false,
    // Disables and hides the street view button
    streetViewControl: false,
};
//#endregion

export type GoogleMapsHandle = {
    highlightOrHideSchoolDistrictPolygon: (schoolDistrict: SchoolDistrictDTO, hidden: boolean) => void;
    onPropertyHoverOrLeaveOnMap: (property: PropertyDTO, hovered: boolean) => void;
    onNeighborhoodHoverOrLeaveOnMap: (neighborhood: NeighborhoodDTO, hovered: boolean) => void;
    setSelectedPropertyOnMap: (property: PropertyDTO | undefined) => void;
    onRecenterClickHandler: () => void;
};

export type GoogleMapsProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * *** TEMP *** Whether the map should have the debug console
     */
    debugMap?: boolean;
    /**
     * Additional overlay components to display on the map
     */
    additionalOverlay?: ReactElement;
    /**
     * List of conveniences to display on the map
     */
    conveniences?: LocationDTO[];
    /**
     * Whether you want the map to be the dark style
     * @default false
     */
    isDarkMode?: boolean;
    /**
     * Keeps the selected property selected when the map is clicked.
     * Used on mobile when a property is always selected based on the carousel.
     */
    keepPropertySelectedOnMapClick?: boolean;
    /**
     * Whether to have keyboard shortcuts active on the map, and to display the button at the bottom right
     */
    keyboardShortcuts?: boolean;
    /**
     * The minimum value of zoom on the map
     * @default 5 - DEFAULT_MIN_ZOOM
     */
    minZoom?: number;
    /**
     * The neighborhoods with drone images we should render
     */
    neighborhoods?: NeighborhoodDTO[];
    /**
     * The properties we may want to render the polygon of
     */
    properties?: PropertyDTO[];
    /**
     * The starting perimeter to fit bounds around
     */
    perimeter?: number[][];
    /**
     * Whether the neighborhood image should replace the icon in close zoom
     * @default true
     */
    showNeighborhoodImage?: boolean;
    /**
     * Should we render the polygons of the property
     * @default true
     */
    showProperties?: boolean;
    /**
     * Whether this map should show the recenter button
     * @default false
     */
    showRecenterButton?: boolean;
    /**
     * The starting position of the map
     * @type {Point}
     * @default {lat:36.1524506,lng:-95.9843024} - DEFAULT_CENTER
     */
    startingCenter?: Point;
    /**
     * The property to start as being selected
     */
    startingSelectedProperty?: PropertyDTO;
    /**
     * The starting zoom of the map
     * @default 12 - DEFAULT_ZOOM
     */
    startingZoom?: number;
    /**
     * What to do when a property's polygon is clicked on the map
     */
    onPropertyClick?: (selectedProperty: PropertyDTO | undefined) => void;
    /**
     * Set the map bound values when it changes for filtering
     */
    setMapBounds?: (mapBounds: Bounds) => void;
};

export const GoogleMaps = forwardRef(
    (
        {
            className,
            additionalOverlay,
            conveniences,
            debugMap = false,
            isDarkMode = false,
            keepPropertySelectedOnMapClick,
            keyboardShortcuts,
            minZoom = DEFAULT_MIN_ZOOM,
            neighborhoods,
            perimeter,
            properties,
            showNeighborhoodImage,
            showProperties,
            startingCenter = DEFAULT_CENTER,
            startingSelectedProperty,
            startingZoom = DEFAULT_ZOOM,
            onPropertyClick,
            setMapBounds,
        }: GoogleMapsProps,
        ref: React.ForwardedRef<GoogleMapsHandle>
    ) => {
        const googleMapsOverlayRef = useRef<GoogleMapsHandle>(null);
        const apiLoadingStatus = useApiLoadingStatus();

        // Memoize to not cause google maps bounds change
        const mapBounds = useMemo(() => {
            if (perimeter) {
                return getGoogleBoundsCoordinates(perimeter);
            }
        }, [perimeter]);

        //#region Imperative Handle
        // Wrap children exposed functions to pass up
        function setSelectedPropertyOnMap(property: PropertyDTO | undefined) {
            googleMapsOverlayRef.current?.setSelectedPropertyOnMap(property);
        }

        function onPropertyHoverOrLeaveOnMap(property: PropertyDTO, hovered: boolean) {
            googleMapsOverlayRef.current?.onPropertyHoverOrLeaveOnMap(property, hovered);
        }

        function onNeighborhoodHoverOrLeaveOnMap(neighborhood: NeighborhoodDTO, hovered: boolean) {
            googleMapsOverlayRef.current?.onNeighborhoodHoverOrLeaveOnMap(neighborhood, hovered);
        }

        function highlightOrHideSchoolDistrictPolygon(schoolDistrict: SchoolDistrictDTO, hidden: boolean) {
            googleMapsOverlayRef.current?.highlightOrHideSchoolDistrictPolygon(schoolDistrict, hidden);
        }

        function onRecenterClickHandler() {
            googleMapsOverlayRef.current?.onRecenterClickHandler();
        }

        useImperativeHandle(ref, () => ({
            highlightOrHideSchoolDistrictPolygon,
            onPropertyHoverOrLeaveOnMap,
            onNeighborhoodHoverOrLeaveOnMap,
            setSelectedPropertyOnMap,
            onRecenterClickHandler,
        }));
        //#endregion

        function getDefaultMapSettings(mapBounds?: google.maps.LatLngBoundsLiteral) {
            const mapId = isDarkMode ? DARK_GOOGLE_MAPS_MAP_ID : LIGHT_GOOGLE_MAPS_MAP_ID;
            const commonSettings = { className: styles.map, mapId, keyboardShortcuts, minZoom, ...DEFAULT_SETTINGS };

            if (mapBounds) {
                return { defaultBounds: mapBounds, ...commonSettings };
            }

            return { defaultCenter: startingCenter, defaultZoom: startingZoom, ...commonSettings };
        }

        const classes = classNames(styles.root, className);

        if (apiLoadingStatus === APILoadingStatus.NOT_LOADED || apiLoadingStatus === APILoadingStatus.LOADING) {
            return <LoadingSpinner className={classes} />;
        }

        return (
            <div className={classes}>
                <Map {...getDefaultMapSettings(mapBounds)}>
                    <GoogleMapsOverlay
                        ref={googleMapsOverlayRef}
                        bounds={mapBounds}
                        conveniences={conveniences}
                        debugMap={debugMap}
                        isDarkMode={isDarkMode}
                        keepPropertySelectedOnMapClick={keepPropertySelectedOnMapClick}
                        neighborhoods={neighborhoods}
                        properties={properties}
                        showNeighborhoodImage={showNeighborhoodImage}
                        showProperties={showProperties}
                        startingCenter={startingCenter}
                        startingSelectedProperty={startingSelectedProperty}
                        startingZoom={startingZoom}
                        onPropertyClick={onPropertyClick}
                        setMapBounds={setMapBounds}
                    />
                </Map>
                {additionalOverlay}
                {showProperties && <GoogleMapsLegend />}
            </div>
        );
    }
);
