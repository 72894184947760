import { Orientation } from "../../../utilities/enums/Orientation";
import { BaseIcon, BaseIconProps } from "../base-icon";

export interface ChevronIconProps extends BaseIconProps {
    /**
     * The orientation the arrow is facing
     */
    arrowDirection?: Orientation.LEFT | Orientation.RIGHT | Orientation.UP | Orientation.DOWN;
}

export function ChevronIcon({
    arrowDirection = Orientation.RIGHT,
    className,
    fillColor = "none",
    strokeLinecap = "round",
    strokeWidth = 2,
    ...baseIconProps
}: ChevronIconProps) {
    const props = {
        className,
        fillColor,
        strokeLinecap,
        strokeWidth,
    };

    if (arrowDirection === Orientation.RIGHT) {
        return (
            <BaseIcon viewBox="0 0 10 20" {...props} {...baseIconProps}>
                <path d="M1 1 8.75 10 1 19" />
            </BaseIcon>
        );
    }

    if (arrowDirection === Orientation.UP) {
        return (
            <BaseIcon viewBox="0 0 20 10" {...props} {...baseIconProps}>
                <path d="M1 9 10 1.25 19 9" />
            </BaseIcon>
        );
    }

    if (arrowDirection === Orientation.DOWN) {
        return (
            <BaseIcon viewBox="0 0 20 10" {...props} {...baseIconProps}>
                <path d="M1 1 10 8.75 19 1" />
            </BaseIcon>
        );
    }

    return (
        <BaseIcon viewBox="0 0 10 20" {...props} {...baseIconProps}>
            <path d="M9 1 1.25 10 9 19" />
        </BaseIcon>
    );
}
