import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import React from "react";

import { Constants } from "../../../utilities/Constants";
import { Orientation } from "../../../utilities/enums/Orientation";
import { putLeadingZeroIfNeeded } from "../../../utilities/formatting/putLeadingZeroIfNeeded";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { ChevronIcon } from "../../icons/chevron-icon";
import { FullScreenGallery } from "../../misc/full-screen-gallery/full-screen-gallery";

import styles from "./base-carousel.module.scss";
import arrowsStyles from "./carousel-arrows.module.scss";
import controlsStyles from "./carousel-controls.module.scss";
import dotsStyles from "./carousel-dots.module.scss";
import classNames from "classnames";

const CAROUSEL_ID = "carousel";
// Percent of slide that needs to be dragged to go to next slide
const DRAG_THRESHOLD = 0.25;
// Delay Before the swipeable hint animation starts
const SWIPEABLE_HINT_ANIMATION_DELAY = 1000;
// Duration of the swipeable hint animation
const SWIPEABLE_HINT_ANIMATION_DURATION = 2000;

enum DragOrientation {
    VERTICAL,
    HORIZONTAL,
}

export enum CarouselArrowStyle {
    BORDERED_EXTERIOR_ARROWS,
    EXTERIOR_ARROWS,
    INTERIOR_ARROWS,
    LARGE_INTERIOR_ARROWS,
}

export enum CarouselDotsStyle {
    EXTERIOR_DOTS,
    INTERIOR_DOTS,
}

function getClassFromCarouselArrowStyle(buttonStyle: CarouselArrowStyle) {
    if (buttonStyle === CarouselArrowStyle.EXTERIOR_ARROWS) {
        return arrowsStyles.exteriorArrows;
    }

    if (buttonStyle === CarouselArrowStyle.BORDERED_EXTERIOR_ARROWS) {
        return classNames(arrowsStyles.exteriorArrows, arrowsStyles.bordered);
    }

    if (buttonStyle === CarouselArrowStyle.LARGE_INTERIOR_ARROWS) {
        return classNames(arrowsStyles.interiorArrows, arrowsStyles.large);
    }

    return arrowsStyles.interiorArrows;
}

function getClassFromCarouselDotsStyle(dotsStyle: CarouselDotsStyle) {
    if (dotsStyle === CarouselDotsStyle.EXTERIOR_DOTS) {
        return classNames(dotsStyles.root, dotsStyles.exterior);
    }

    return dotsStyles.root;
}

export type BaseCarouselProps = {
    /**
     * Additional classnames for the carousel
     */
    className?: string;
    /**
     * Additional class name for the arrows only
     */
    arrowsClassName?: string;
    /**
     * Additional classnames for controls div
     */
    controlsClassName?: string;
    /**
     * Additional classnames for dots wrapper div
     */
    dotsClassName?: string;
    /**
     * Additional class name for the slide number counter only
     */
    slideNumberClassName?: string;
    /**
     * Array of react elements representing the slides.
     */
    children?: ReactElement[];
    /**
     * Set to true to enable continuous infinite mode
     * @default false
     */
    arrows?: boolean;
    /**
     * The arrow style you want on this carousel
     */
    arrowsStyle?: CarouselArrowStyle;
    /**
     * Whether this is a controls carousel.
     * If this it true, dots, arrows and slide number overlay all will be disabled
     * @default false
     */
    controls?: boolean;
    /**
     * Override for the index of the current slide to be on
     */
    currentSlide?: number;
    /**
     * Whether the carousel should have dots
     * @default false
     */
    dots?: boolean;
    /**
     * The style you want the dots to have on this carousel
     */
    dotsStyle?: CarouselDotsStyle;
    /**
     * Whether or not the carousel is draggable
     * @default true
     */
    draggable?: boolean;
    /**
     * Whether you want the carousel to have a fade animation on slide change
     * @default false
     */
    fade?: boolean;
    /**
     * A flag to determine if the full screen gallery should open when the content is click
     * The full screen gallery is used to display the gallery in full screen mode
     * It will be a vertical list scroller in mobile and a horizontal slider in desktop
     * @default false
     */
    hasFullScreenGallery?: boolean;
    /**
     * Set to true to enable continuous infinite mode
     * @default false
     */
    infinite?: boolean;
    /**
     * The number of slides to preload in each direction for the carousel
     * @default 2x slidesperview
     */
    numberOfSlidesToPreload?: number;
    /**
     * Whether to show carousel overflow or not
     * @default false
     */
    showOverflow?: boolean;
    /**
     * Whether the carousel should show a swipeable hint animation
     * @default false
     */
    showSwipeableHintAnimation?: boolean;
    /**
     * Whether we want to show the slide number or not
     * @default false
     */
    showSlideNumber?: boolean;
    /**
     * The Gap wanted between slides;
     * @default 0
     */
    slideGap?: number;
    /**
     * The amount of slides to go per click
     * @default slidesPerView
     */
    slidesPerClick?: number;
    /**
     * Number of slides per view (slides visible at the same time on slider's container)
     * @default 1
     */
    slidesPerView?: number;
    /**
     * The transition time in milliseconds between slides when animating
     * @default 300
     */
    transitionTimeInMs?: number;
    /**
     * Event to happen before the slide is about to change
     */
    beforeChange?: (currentSlide: number, nextSlide: number) => void;
};
export function BaseCarousel({
    arrowsClassName,
    className,
    controlsClassName,
    dotsClassName,
    slideNumberClassName,
    children = [],
    arrows = false,
    arrowsStyle = CarouselArrowStyle.EXTERIOR_ARROWS,
    controls = false,
    currentSlide,
    slideGap = 0,
    dots = false,
    dotsStyle = CarouselDotsStyle.EXTERIOR_DOTS,
    draggable = true,
    fade = false,
    hasFullScreenGallery = false,
    infinite = true,
    showOverflow = false,
    showSwipeableHintAnimation = false,
    showSlideNumber = false,
    slidesPerView = 1,
    slidesPerClick = slidesPerView,
    numberOfSlidesToPreload = 2 * slidesPerView,
    transitionTimeInMs = 300,
    beforeChange,
}: BaseCarouselProps) {
    const carouselRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLDivElement>(null);

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [nextActiveSlideIndex, setNextActiveSlideIndex] = useState(0);
    const [isSliding, setSliding] = useState(false);
    const [pagingDirection, setPagingDirection] = useState<Orientation.LEFT | Orientation.RIGHT | null>(null);

    const { startIndex, endIndex } = getStartAndEndIndexToRender(activeSlideIndex);
    const [offset, setOffset] = useState(getOffset(startIndex, activeSlideIndex));

    const [dragging, setDragging] = useState(false);
    const [dragStartOffset, setDragStartOffset] = useState(0);
    const [dragStartX, setDragStartX] = useState(0);
    const [dragStartY, setDragStartY] = useState(0);
    const [dragDeltaX, setDragDeltaX] = useState(0);
    const [dragOrientation, setDragOrientation] = useState<DragOrientation>();

    const [isFullScreenGalleryOpen, setFullScreenGalleryOpen] = useState<boolean>(false);

    //#region UseEffects
    useEffect(() => {
        if (!sliderRef.current || !showSwipeableHintAnimation) {
            return;
        }

        const slider = sliderRef.current;

        setSliding(true);
        const startingOffset = offset;
        const oneWayDuration = SWIPEABLE_HINT_ANIMATION_DURATION / 2;
        slider.style.transition = `transform ${oneWayDuration}ms ease`;

        setTimeout(() => {
            setOffset(startingOffset - 75);

            setTimeout(() => {
                setOffset(startingOffset);

                setTimeout(() => {
                    slider.style.transition = `none`;
                    setSliding(false);
                }, oneWayDuration);
            }, oneWayDuration);
        }, SWIPEABLE_HINT_ANIMATION_DELAY);
    }, [sliderRef.current]);

    //#region Drag Handlers
    const handleDragStart = useCallback(
        (event: MouseEvent | TouchEvent) => {
            if (isSliding) {
                return;
            }

            // If mobile use touches otherwise directly use clientX/Y
            const startX = "touches" in event ? event.touches[0].clientX : event.clientX;
            // Track Y position to see if this is a horizontal or vertical scroll
            const startY = "touches" in event ? event.touches[0].clientY : event.clientY;

            setDragStartOffset(offset);
            setDragging(true);
            setDragStartX(startX);
            setDragStartY(startY);
        },
        [offset, isSliding]
    );

    const handleDragMove = useCallback(
        (event: MouseEvent | TouchEvent) => {
            if (!dragging || !carouselRef.current || dragOrientation === DragOrientation.VERTICAL) {
                return;
            }

            const currentX = "touches" in event ? event.touches[0].clientX : event.clientX;
            const deltaX = currentX - dragStartX;

            if (dragOrientation === undefined) {
                const currentY = "touches" in event ? event.touches[0].clientY : event.clientY;
                const deltaY = currentY - dragStartY; // Calculate vertical movement

                // Check if vertical drag is dominant
                if (Math.abs(deltaY) > Math.abs(deltaX)) {
                    setDragOrientation(DragOrientation.VERTICAL);
                    // Don't slide the carousel if it's a vertical drag
                    return;
                }

                setDragOrientation(DragOrientation.HORIZONTAL);
            }

            event.preventDefault();

            const slideOffset = (deltaX / carouselRef.current.offsetWidth) * 100;
            const newOffset = getInBoundsOffset(dragStartOffset + slideOffset);

            setDragDeltaX(deltaX);
            setOffset(newOffset);
        },
        [dragStartX, dragStartY, dragging, dragOrientation, dragStartOffset]
    );

    const handleDragEnd = useCallback(() => {
        if (!dragging || !carouselRef.current || dragOrientation === DragOrientation.VERTICAL) {
            setDragging(false);
            setDragOrientation(undefined);
            return;
        }

        const slideWidth = carouselRef.current.offsetWidth / slidesPerView;
        const inverseDraggedDirection = Math.sign(-1 * dragDeltaX);
        const draggedAbsolutePercent = Math.abs(dragDeltaX / slideWidth);
        // Use inverse drag direction as when you drag left slide index increases
        const draggedSlides = inverseDraggedDirection * Math.ceil(draggedAbsolutePercent - DRAG_THRESHOLD);

        setDragging(false);
        setDragDeltaX(0);
        slideBy(draggedSlides, dragStartOffset);
        setDragOrientation(undefined);
    }, [dragging, dragOrientation, dragDeltaX, dragStartOffset]);
    //#endregion

    useEffect(() => {
        if (!sliderRef.current || !draggable || fade) {
            return;
        }

        const slider: HTMLElement = sliderRef.current;

        slider.addEventListener("mousedown", handleDragStart);
        slider.addEventListener("touchstart", handleDragStart);
        // Need passive: false in order to be able to prevent default (scrolling) in the function
        slider.addEventListener("mousemove", handleDragMove, { passive: false });
        slider.addEventListener("touchmove", handleDragMove, { passive: false });
        slider.addEventListener("mouseup", handleDragEnd);
        slider.addEventListener("mouseleave", handleDragEnd);
        slider.addEventListener("touchend", handleDragEnd);
        slider.addEventListener("touchcancel", handleDragEnd);

        return () => {
            slider.removeEventListener("mousedown", handleDragStart);
            slider.removeEventListener("touchstart", handleDragStart);
            slider.removeEventListener("mousemove", handleDragMove);
            slider.removeEventListener("touchmove", handleDragMove);
            slider.removeEventListener("mouseup", handleDragEnd);
            slider.removeEventListener("mouseleave", handleDragEnd);
            slider.removeEventListener("touchend", handleDragEnd);
            slider.removeEventListener("touchcancel", handleDragEnd);
        };
    }, [sliderRef.current, draggable, fade, handleDragStart, handleDragMove, handleDragEnd]);

    useEffect(
        () => {
            if (!sliderRef.current || !draggable) {
                return;
            }

            const slider: HTMLElement = sliderRef.current;
            applyNonDraggablePropsRecursively(slider);
        },
        // Reapply when activeSlideIndex changes to apply to newly rendered slides
        [activeSlideIndex, sliderRef.current]
    );

    useEffect(() => {
        if (!currentSlide) {
            return;
        }

        const nextActiveSlideIndexWithinChildrenLength = getSlideIndexWithinChildrenLength(nextActiveSlideIndex);
        // When current slide from parent is changed and we are not animating go to that slide
        if (nextActiveSlideIndexWithinChildrenLength !== currentSlide) {
            setActiveSlideIndex(currentSlide);
        }
    }, [currentSlide]);
    //#endregion

    function _beforeChange(nextSlide: number) {
        updatePagingDirection(nextSlide);

        setNextActiveSlideIndex(nextSlide);

        if (beforeChange) {
            const activeSlideIndexWithinChildrenLength = getSlideIndexWithinChildrenLength(activeSlideIndex);
            const nextSlideIndexWithinChildrenLength = getSlideIndexWithinChildrenLength(nextSlide);
            beforeChange(activeSlideIndexWithinChildrenLength, nextSlideIndexWithinChildrenLength);
        }
    }

    function carouselOnClick(event: React.MouseEvent | React.TouchEvent) {
        const stopped = stopPropagationOnButtonElementClick(event);
        if (!stopped && hasFullScreenGallery) {
            setFullScreenGalleryOpen(true);
        }
    }

    //#region Util Functions
    /**
     * Called during the _beforeChange, used to update the state of the paging direction
     * to ensure that the dots on dots carousels animate correctly
     *
     * @param nextSlide The slide you are sliding to
     * @returns
     */
    function updatePagingDirection(nextSlide: number) {
        // Only needed if this is a dots carousel
        if (!dots) {
            return;
        }

        if (activeSlideIndex < nextSlide) {
            setPagingDirection(Orientation.RIGHT);
        } else if (activeSlideIndex > nextSlide) {
            setPagingDirection(Orientation.LEFT);
        }
    }

    /**
     * Check to see if a button inside of the carousel wrapper was clicked
     * If so stop propagation and prevent default on the event
     *
     * @param event
     * @returns
     */
    function stopPropagationOnButtonElementClick(event: React.MouseEvent | React.TouchEvent): boolean {
        let targetInButton = false;
        let elementToCheck: Element | null = event.target as Element;

        // Loop through the targets elements parents to see if any are button until you get to the wrapper div
        while (!targetInButton && elementToCheck && elementToCheck.id !== CAROUSEL_ID) {
            if (elementToCheck.tagName.toLowerCase() === "button") {
                targetInButton = true;
                break;
            }
            elementToCheck = elementToCheck.parentElement;
        }

        // Stop propagation if one of the carousels buttons is clicked
        if (targetInButton) {
            event.preventDefault();
            event.stopPropagation();
            return true;
        }

        return false;
    }

    /**
     * Once the slider lands on the new slide, reset the slider transform x (offset)
     * to make the slide you just landed on the center of the slider again
     *
     * @param nextSlideIndex The slide you arrived at after sliding
     */
    function resetSliderOffset(nextSlideIndex: number) {
        const { startIndex: newStartIndex } = getStartAndEndIndexToRender(nextSlideIndex);
        const newOffset = getOffset(newStartIndex, nextSlideIndex);

        setActiveSlideIndex(nextSlideIndex);
        setOffset(newOffset); // Reset the offset after animation
        setSliding(false);
    }

    /**
     * Slides a given amount of slides
     *
     * @param count The number of slides you want to slide can be positive or negative for direction
     * @param startingOffset The offset we are starting the slide from in the case the carousel has been dragged
     * @default offset
     */
    function slideBy(count: number, startingOffset: number = offset) {
        let nextSlideIndex = activeSlideIndex + count;

        // If not infinite carousel make sure new slide index is in bounds
        if (!infinite) {
            nextSlideIndex = Math.max(nextSlideIndex, 0);
            nextSlideIndex = Math.min(nextSlideIndex, children.length - slidesPerView);
        }

        _beforeChange(nextSlideIndex);

        if (fade) {
            setActiveSlideIndex(nextSlideIndex);
            return;
        }

        const slideOffsetAmount = ((activeSlideIndex - nextSlideIndex) * 100) / slidesPerView;
        const newOffset = getInBoundsOffset(startingOffset + slideOffsetAmount);

        setSliding(true);
        setOffset(newOffset);

        setTimeout(() => {
            resetSliderOffset(nextSlideIndex);
        }, transitionTimeInMs);
    }

    function pressArrow(direction: Orientation.LEFT | Orientation.RIGHT) {
        if (isSliding) {
            return;
        }

        if (direction === Orientation.RIGHT) {
            slideBy(slidesPerClick);
            return;
        }

        slideBy(-1 * slidesPerClick);
    }

    /**
     * Gets the offset difference between two slide index's
     *
     * @param _startIndex The starting index to get the difference from
     * @param _activeSlideIndex The ending index to compare from the start
     * @returns The offset difference between the two slides
     */
    function getOffset(_startIndex: number, _activeSlideIndex: number) {
        const newOffset = (_startIndex - _activeSlideIndex) * (100 / slidesPerView);

        return getInBoundsOffset(newOffset);
    }

    /**
     * Given an offset if a carousel is not infinite makes sure the offset is not past the beginning or end of the carousel
     *
     * @param offset The offset to get an inBounds offset for
     * @returns An offset in bounds if carousel is not infinite
     */
    function getInBoundsOffset(offset: number) {
        if (infinite) {
            return offset;
        }

        // Maximum offset (position of the first slide)
        const maxOffset = 0;
        // Min offset at end of carousel
        const minOffset = -((children.length - slidesPerView) / slidesPerView) * 100;

        let newOffset = offset;
        // Make sure offset isn't past beginning of carousel
        newOffset = Math.max(newOffset, minOffset);
        // Make sure offset isn't past end of carousel
        newOffset = Math.min(newOffset, maxOffset);

        return newOffset;
    }

    /**
     * Given an index it converts it either up or down to be within child index bounds
     *
     * @param index The index to convert to an index within children bounds
     * @returns The index in the children array
     */
    function getSlideIndexWithinChildrenLength(index: number) {
        if (children.length === 0) {
            return 0;
        }

        // Add children.length until you get to a positive index
        while (index < 0) {
            index += children.length;
        }

        // Account for overflow on positive side
        return index % children.length;
    }

    /**
     * Given a starting position get the index of the first and last slide that will be rendered
     *
     * @param currentSlideIndex The starting index
     * @returns  The first and last index that will be rendered in the slider
     */
    function getStartAndEndIndexToRender(currentSlideIndex: number): { startIndex: number; endIndex: number } {
        const startIndex = currentSlideIndex - numberOfSlidesToPreload;
        // + slidesPerView because we want to prerender slides that are past the last slide in view
        const endIndex = currentSlideIndex + slidesPerView + numberOfSlidesToPreload;

        if (infinite) {
            return { startIndex, endIndex };
        }

        // If not infinite cap start and end index at beginning and end of array
        return { startIndex: Math.max(startIndex, 0), endIndex: Math.min(endIndex, children.length - 1) };
    }

    // Function to recursively apply draggable false prop to child elements
    function applyNonDraggablePropsRecursively(element: Element) {
        if (!element.children) {
            return;
        }

        Array.from(element.children).forEach((child) => {
            child.setAttribute("draggable", "false");
            applyNonDraggablePropsRecursively(child);
        });
    }
    //#endregion

    //#region Render Functions
    function getDot(index: number) {
        const totalPages = children.length;
        // Use next active slide index instead of active, to not have the dots delay until after the slides transition animation is complete
        const nextActiveSlideIndexWithinChildrenLength = getSlideIndexWithinChildrenLength(nextActiveSlideIndex);
        let className = dotsStyles.removed;

        // Determine the primary class based on position
        if (index === nextActiveSlideIndexWithinChildrenLength - 2) {
            className = dotsStyles.outerBefore;
        } else if (index === nextActiveSlideIndexWithinChildrenLength - 1) {
            className = dotsStyles.innerBefore;
        } else if (index === nextActiveSlideIndexWithinChildrenLength) {
            className = dotsStyles.active;
        } else if (index === nextActiveSlideIndexWithinChildrenLength + 1) {
            className = dotsStyles.innerAfter;
        } else if (index === nextActiveSlideIndexWithinChildrenLength + 2) {
            className = dotsStyles.outerAfter;
        }

        // Handle edge cases for specific slide positions
        if (nextActiveSlideIndexWithinChildrenLength === 0 && (index === 3 || index === 4)) {
            className = classNames(dotsStyles.outerAfter, dotsStyles.inPlace);
        } else if (nextActiveSlideIndexWithinChildrenLength === 1 && index === 4) {
            className = classNames(dotsStyles.outerAfter, dotsStyles.inPlace);
        } else if (nextActiveSlideIndexWithinChildrenLength === totalPages - 2 && index === totalPages - 5) {
            className = classNames(dotsStyles.outerBefore, dotsStyles.inPlace);
        } else if (nextActiveSlideIndexWithinChildrenLength === totalPages - 1 && (index === totalPages - 4 || index === totalPages - 5)) {
            className = classNames(dotsStyles.outerBefore, dotsStyles.inPlace);
        }

        const onClick = () => {
            slideBy(index - nextActiveSlideIndexWithinChildrenLength);
        };

        return <li className={className} key={index} onClick={onClick} />;
    }

    function getDotsWrapper() {
        const totalPages = children.length;
        const slideIndexWithinChildrenLength = getSlideIndexWithinChildrenLength(nextActiveSlideIndex);

        const ulClasses = [];

        // Determine direction class for animation
        if (pagingDirection === Orientation.LEFT) {
            ulClasses.push(dotsStyles.leftToRight);
        } else if (pagingDirection === Orientation.RIGHT) {
            ulClasses.push(dotsStyles.rightToLeft);
        }

        // Handle edge cases for specific slide positions
        if (slideIndexWithinChildrenLength === 0) {
            ulClasses.push(dotsStyles.toActiveFirst);
        } else if (slideIndexWithinChildrenLength === 1) {
            ulClasses.push(dotsStyles.toActiveSecond);
        } else if (slideIndexWithinChildrenLength === totalPages - 2) {
            ulClasses.push(dotsStyles.toActiveSecondFromLast);
        } else if (slideIndexWithinChildrenLength === totalPages - 1) {
            ulClasses.push(dotsStyles.toActiveLast);
        }

        const dots = children.map((_, index) => getDot(index));

        // Add the dots class name last so consumer can override styles
        const ulClassNames = classNames(dotsStyles.dots, ulClasses, dotsClassName);

        return <ul className={ulClassNames}>{dots}</ul>;
    }

    function getSlideNumberDisplay() {
        if (!showSlideNumber) {
            return;
        }

        const slideNumberClasses = classNames(styles.slideNumber, slideNumberClassName);
        const activeSlideIndexWithinChildrenLength = getSlideIndexWithinChildrenLength(nextActiveSlideIndex);
        const activeSlideText = putLeadingZeroIfNeeded(activeSlideIndexWithinChildrenLength + 1);
        const totalSlideCountText = `${Constants.SPACE_ESCAPE_CHARACTER}/ ${putLeadingZeroIfNeeded(children.length)}`;

        return (
            <div className={slideNumberClasses}>
                <div className={styles.activeSlideNumber}>{activeSlideText}</div>
                <div>{totalSlideCountText}</div>
            </div>
        );
    }

    function getArrows() {
        let leftDisabled = false;
        let rightDisabled = false;
        if (!infinite) {
            leftDisabled = nextActiveSlideIndex === 0;
            rightDisabled = nextActiveSlideIndex >= children.length - slidesPerView;
        }

        const leftArrowClasses = classNames(
            arrowsStyles.arrowButton,
            arrowsStyles.left,
            leftDisabled && arrowsStyles.hidden,
            arrowsClassName
        );
        const rightArrowClasses = classNames(
            arrowsStyles.arrowButton,
            arrowsStyles.right,
            rightDisabled && arrowsStyles.hidden,
            arrowsClassName
        );

        return (
            <>
                <button className={leftArrowClasses} onClick={() => pressArrow(Orientation.LEFT)} disabled={leftDisabled}>
                    <ChevronIcon className={arrowsStyles.chevron} arrowDirection={Orientation.LEFT} />
                </button>
                <button className={rightArrowClasses} onClick={() => pressArrow(Orientation.RIGHT)} disabled={rightDisabled}>
                    <ChevronIcon className={arrowsStyles.chevron} arrowDirection={Orientation.RIGHT} />
                </button>
            </>
        );
    }

    function getControls() {
        const activeSlideIndexWithinChildrenLength = getSlideIndexWithinChildrenLength(nextActiveSlideIndex);
        const activeSlideText = putLeadingZeroIfNeeded(activeSlideIndexWithinChildrenLength + 1);
        const totalSlideCountText = `${Constants.SPACE_ESCAPE_CHARACTER}/ ${putLeadingZeroIfNeeded(children.length)}`;

        const leftArrowClasses = classNames(controlsStyles.arrowButton, controlsStyles.leftArrowButton);
        const rightArrowClasses = classNames(controlsStyles.arrowButton, controlsStyles.rightArrowButton);
        const carouselInformationWrapperClasses = classNames(controlsStyles.carouselInformation, controlsClassName);

        // This is used to line up the left and right edges of the carousel information wrapper with the edges of the slides
        const carouselInformationWrapperStyles = { padding: `0 ${slideGap / 2}px` };

        return (
            <div style={carouselInformationWrapperStyles} className={carouselInformationWrapperClasses}>
                <div className={controlsStyles.slideCounter}>
                    <div className={controlsStyles.numerator}>{activeSlideText}</div>
                    <div className={controlsStyles.denominator}>{totalSlideCountText}</div>
                </div>
                <div className={controlsStyles.centerLine} />
                <div>
                    <BaseButton className={leftArrowClasses} buttonStyle={ButtonStyle.NONE} onClick={() => pressArrow(Orientation.LEFT)}>
                        <ChevronIcon className={controlsStyles.chevronIcon} arrowDirection={Orientation.LEFT} />
                    </BaseButton>
                    <BaseButton className={rightArrowClasses} buttonStyle={ButtonStyle.NONE} onClick={() => pressArrow(Orientation.RIGHT)}>
                        <ChevronIcon className={controlsStyles.chevronIcon} arrowDirection={Orientation.RIGHT} />
                    </BaseButton>
                </div>
            </div>
        );
    }

    function getCarouselOverlay() {
        if (controls) {
            return getControls();
        }

        return (
            <>
                {showSlideNumber && getSlideNumberDisplay()}
                {arrows && getArrows()}
                {dots && getDotsWrapper()}
                {isFullScreenGalleryOpen && <FullScreenGallery close={() => setFullScreenGalleryOpen(false)}>{children}</FullScreenGallery>}
            </>
        );
    }

    function renderChildren() {
        return Array.from({ length: endIndex - startIndex + 1 }, (_, index) => {
            const slideIndex = startIndex + index;
            const slideIndexWithinChildrenLength = getSlideIndexWithinChildrenLength(slideIndex);
            const slideClasses = classNames(
                styles.slide,
                fade && styles.fade,
                // This is used to hide the slide when a slide needs to fade out
                slideIndex !== activeSlideIndex && styles.hidden
            );

            // This is a percent so that it uses the render pane's width instead of the parent's width
            const flex = `0 0 ${100 / slidesPerView}%`;
            // This is slideGap / 2 so that the cards are centered while also having a gap
            const padding = `0 ${slideGap / 2}px`;
            // This is used to give the cards with fade a animation duration of transitionTimeInMs time
            const transition = fade ? `opacity ${transitionTimeInMs}ms ease-in` : "none";
            const slideStyles = {
                flex,
                padding,
                transition,
            };

            return (
                <div
                    key={slideIndex} // Use the original slide index before modding as a stable key
                    style={slideStyles}
                    className={slideClasses}
                >
                    {children[slideIndexWithinChildrenLength]}
                </div>
            );
        });
    }

    function getSliderStyles() {
        if (fade) {
            return;
        }

        const transform = `translateX(${offset}%)`;
        const transition = isSliding ? `transform ${transitionTimeInMs}ms ease` : "none";

        return {
            transform,
            transition,
        };
    }
    //#endregion

    const classes = classNames(
        styles.root,
        arrows && getClassFromCarouselArrowStyle(arrowsStyle),
        controls && controlsStyles.root,
        dots && getClassFromCarouselDotsStyle(dotsStyle),
        className
    );
    const carouselSliderWrapperClasses = classNames(
        styles.carouselSliderWrapper,
        controls && controlsStyles.carouselSliderWrapper,
        !showOverflow && styles.overflowHidden
    );

    return (
        <div key={CAROUSEL_ID} ref={carouselRef} className={classes} onClick={carouselOnClick}>
            {/* Wrap slider with div to hide overflow on the div, so carousel overflown isn't shown under external buttons */}
            <div className={carouselSliderWrapperClasses}>
                <div ref={sliderRef} style={getSliderStyles()} className={styles.carouselSlider}>
                    {renderChildren()}
                </div>
            </div>
            {getCarouselOverlay()}
        </div>
    );
}
