import { useId } from "react";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { Orientation } from "../../../utilities/enums/Orientation";
import { NextStepsCardContent } from "../../blocks/details-blocks/next-steps-details-block/next-steps-content";
import { NextStepsCard } from "../../cards/next-steps-card";
import { NextStepsSvgMask } from "../../cards/next-steps-card/next-steps-svg-mask";

import styles from "./next-steps-tab-content.module.scss";
import classNames from "classnames";

import nextSteps1Image from "../../../assets/components/next-steps-tab-content/NextSteps1Image.jpg";
import nextSteps2Image from "../../../assets/components/next-steps-tab-content/NextSteps2Image.jpg";
import nextSteps3Image from "../../../assets/components/next-steps-tab-content/NextSteps3Image.jpg";
import nextSteps4Image from "../../../assets/components/next-steps-tab-content/NextSteps4Image.jpg";
import nextSteps5Image from "../../../assets/components/next-steps-tab-content/NextSteps5Image.jpg";
import nextSteps6Image from "../../../assets/components/next-steps-tab-content/NextSteps6Image.jpg";
import nextSteps7Image from "../../../assets/components/next-steps-tab-content/NextSteps7Image.jpg";
import nextSteps8Image from "../../../assets/components/next-steps-tab-content/NextSteps8Image.jpg";
import nextSteps9Image from "../../../assets/components/next-steps-tab-content/NextSteps9Image.jpg";

const desktopImages = [nextSteps1Image, nextSteps5Image, nextSteps4Image];
const mobileImages = [
    [nextSteps1Image, nextSteps2Image, nextSteps3Image],
    [nextSteps4Image, nextSteps5Image, nextSteps6Image],
    [nextSteps7Image, nextSteps8Image, nextSteps9Image],
];

export type NextStepsTabContentProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The cards to put in this tab
     */
    cardContents?: NextStepsCardContent[];
    /**
     * The index of this tab to determine what background to show
     */
    tabIndex?: number;
};

export function NextStepsTabContent({ className, cardContents = [], tabIndex = 0 }: NextStepsTabContentProps) {
    const contentId = useId();
    const { screenWidth } = useScreenSize();
    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;

    function getCards() {
        return cardContents.map((cardContent, index) => (
            <NextStepsCard key={index} content={cardContent} backgroundImage={getMobileCardBackgroundImage(index)} />
        ));
    }

    function getDesktopCardBackgroundImage() {
        if (isMobile) {
            return;
        }

        return desktopImages[tabIndex];
    }

    function getMobileCardBackgroundImage(cardIndex: number) {
        if (!isMobile) {
            return;
        }

        return mobileImages[tabIndex][cardIndex];
    }

    function getDesktopCardBackgroundImageWithMask() {
        if (isMobile) {
            return;
        }

        return (
            <>
                <img
                    className={styles.backgroundImage}
                    src={getDesktopCardBackgroundImage()}
                    style={{
                        mask: `url(#${contentId})`,
                        WebkitMask: `url(#${contentId})`,
                    }}
                />
                <NextStepsSvgMask id={contentId} className={styles.svgMask} gap={16} orientation={Orientation.ROW} />
            </>
        );
    }

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.inner}>
                {getCards()}
                {getDesktopCardBackgroundImageWithMask()}
            </div>
        </div>
    );
}
