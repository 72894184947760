import { useApplicationSettings } from "../../../hooks/data/useApplicationSettings";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { BaseCarousel } from "../../carousels/base-carousel";
import { VerticalSectionHeader } from "../../headers/vertical-section-header";
import { TheExecutiveDifferenceSlide } from "./the-executive-difference-slide";

import styles from "./the-executive-difference-block.module.scss";

import guaranteedQuality from "../../../assets/components/the-executive-difference-block/GuaranteedQualityImage.jpg";
import premiumFinishesImage from "../../../assets/components/the-executive-difference-block/PremiumFinishesImage.jpg";
import provenValueImage from "../../../assets/components/the-executive-difference-block/ProvenValueImage.jpg";
import techDrivenImage from "../../../assets/components/the-executive-difference-block/TechDrivenImage.jpg";
import desktopAwardWinningEfficiencyImage from "../../../assets/components/the-executive-difference-block/desktop/DesktopAwardWinningEfficiencyImage.jpg";
import desktopCustomLayoutsImage from "../../../assets/components/the-executive-difference-block/desktop/DesktopCustomLayoutsImage.jpg";
import mobileAwardWinningEfficiencyImage from "../../../assets/components/the-executive-difference-block/mobile/MobileAwardWinningEfficiencyImage.jpg";
import mobileCustomLayoutsImage from "../../../assets/components/the-executive-difference-block/mobile/MobileCustomLayoutsImage.jpg";

const slide1 = (
    <TheExecutiveDifferenceSlide
        key={1}
        backgroundImage={premiumFinishesImage}
        title="Premium Finishes"
        subtitle="Our production volume allows your home to be packed with premium finishes at an unbeatable value, including authentic wood floors, vaults with beams and everything in between."
    />
);
const slide4 = (
    <TheExecutiveDifferenceSlide
        key={4}
        backgroundImage={techDrivenImage}
        title="Tech Driven"
        subtitle="Our team of 25 software engineers has automated the construction schedule and optimized our checklists to guarantee you the highest quality home in the most efficient experience possible."
    />
);
const slide6 = (
    <TheExecutiveDifferenceSlide
        key={6}
        backgroundImage={guaranteedQuality}
        title="Guaranteed Quality"
        subtitle="Instead of a one-size-fits-all approach of typical production builders, our proprietary system dynamically tailors each Quality Assurance checklist to the exact finishes in your home."
    />
);

export function TheExecutiveDifferenceBlock() {
    const { screenWidth } = useScreenSize();
    const { statistics } = useApplicationSettings();

    function getSlide2() {
        const customLayoutsImage = isDesktop ? desktopCustomLayoutsImage : mobileCustomLayoutsImage;

        return (
            <TheExecutiveDifferenceSlide
                key={2}
                backgroundImage={customLayoutsImage}
                title="Custom Layouts"
                subtitle="Our 100+ floor plans can be custom-tailored to your family’s needs. Unlike a typical custom builder, we’ll provide you with a customized layout and guaranteed price within 24 hours."
            />
        );
    }

    function getSlide3() {
        const formattedResaleProfit = formatPrice(statistics.resaleProfit, true);

        return (
            <TheExecutiveDifferenceSlide
                key={3}
                backgroundImage={provenValueImage}
                title="Proven Value"
                subtitle={`Our high-end finishes and guaranteed quality has resulted in our homeowners experiencing an incredible average resale profit of ${formattedResaleProfit}!`}
            />
        );
    }

    function getSlide5() {
        const awardWinningEfficiencyImage = isDesktop ? desktopAwardWinningEfficiencyImage : mobileAwardWinningEfficiencyImage;

        return (
            <TheExecutiveDifferenceSlide
                key={5}
                backgroundImage={awardWinningEfficiencyImage}
                title="Award-Winning Efficiency"
                subtitle="Out of all the production builders in the country, Executive received the RESNET President’s Award for the highest percentage of homes that achieve a 51 HERS Rating or better. "
            />
        );
    }

    function getSlides() {
        const slide2 = getSlide2();
        const slide3 = getSlide3();
        const slide5 = getSlide5();

        const slides = [slide1, slide2, slide3, slide4, slide5, slide6];

        return slides;
    }

    function getCarousel() {
        const slides = getSlides();

        return (
            <BaseCarousel
                className={styles.carousel}
                controls={isDesktop}
                dots={!isDesktop}
                showOverflow={true}
                slideGap={32}
                slidesPerView={1}
            >
                {slides}
            </BaseCarousel>
        );
    }

    const isDesktop = screenWidth >= HorizontalBreakpoint.SMALL;

    return (
        <div className={styles.root}>
            <VerticalSectionHeader
                className={styles.blockHeaderSpacing}
                title="THE EXECUTIVE DIFFERENCE"
                subtitle="Executive Homes is Oklahoma's largest premium homebuilder, which allows us the unique ability to blend the value benefits of a production builder and the personalized options of a custom builder. It's a combination unlike any other!"
            />
            {getCarousel()}
        </div>
    );
}
