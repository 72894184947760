import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { DetailsBlock } from "../../components/blocks/details-blocks/details-block";
import { TabPair } from "../../components/blocks/details-blocks/details-block/details-block";
import { StyleBlock } from "../../components/blocks/style-block";
import { StylesComparisonBlock } from "../../components/blocks/styles-comparison-block";
import { BaseButton, ButtonStyle } from "../../components/buttons/base-button";
import { DetailsBlockAttachmentCarousel } from "../../components/carousels/details-block-attachment-carousel";
import { MobilePropertySummaryFooter } from "../../components/footers/mobile-property-summary-footer";
import { StylesPagePreFooter } from "../../components/footers/styles-page-pre-footer";
import { HorizontalSectionHeader } from "../../components/headers/horizontal-section-header";
import { PropertySummaryHeader } from "../../components/headers/property-summary-header";
import { PaintBrushIcon } from "../../components/icons/paint-brush-icon";
import { PhotosIcon } from "../../components/icons/photos-icon";
import { SelectionsIcon } from "../../components/icons/selections-icon";
import { MobileBlockSeparator } from "../../components/misc/mobile-block-separator";
import { MobilePageTabNavigator, NavigationTab } from "../../components/misc/mobile-page-tab-navigator/mobile-page-tab-navigator";
import { PropertyInlineInfo } from "../../components/misc/property-inline-info";
import { usePropertyFlow } from "../../hooks/usePropertyFlow";
import { WizardQueryParameterKeys, useQueryParameters } from "../../hooks/useQueryParameters";
import { useScreenSize } from "../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../utilities/enums/Breakpoints";
import { AppRoute } from "../../utilities/routing/AppRoute";
import { getCombinedQueryParameters } from "../../utilities/urls/getCombinedQueryParameters";
import { getRouteUrl } from "../../utilities/urls/getRouteUrl";
import { Page } from "../page";

import styles from "./styles-page.module.scss";
import classNames from "classnames";

import beigesAndCreamsImage from "../../assets/pages/styles-page/BeigesAndCreamsImage.jpg";
import ceilingDetailsImage from "../../assets/pages/styles-page/CeilingDetailsImage.jpg";
import curatedStylesImage from "../../assets/pages/styles-page/CuratedStylesImage.jpg";
import detailsAtEveryTurnImage from "../../assets/pages/styles-page/DetailsAtEveryTurnImage.jpg";
import everythingBetweenDetailsImage from "../../assets/pages/styles-page/EverythingBetweenDetailsImage.jpg";
import floorDetailsImage from "../../assets/pages/styles-page/FloorDetailsImage.jpg";
import graysAndWhitesImage from "../../assets/pages/styles-page/GraysAndWhitesImage.jpg";
import howToChooseImage from "../../assets/pages/styles-page/HowToChooseImage.jpg";
import signatureStyleImage from "../../assets/pages/styles-page/SignatureStyleImage.jpg";
import transitionalStyleImage from "../../assets/pages/styles-page/TransitionalStyleImage.jpg";
import twoStylesImage from "../../assets/pages/styles-page/TwoStylesImage.png";

const twoStylesTabImages: AttachmentDTO[] = [
    {
        url: twoStylesImage,
        title: "Signature or Transitional?",
        description: "Each floor plan can be built in either of our two distinctive styles, Signature and Transitional.",
    },
    {
        url: transitionalStyleImage,
        title: "Transitional",
        description:
            "Transitional is the intersection of modern and classic details with a neutral palette for the flexibility to make it your own!",
    },
    {
        url: signatureStyleImage,
        title: "Signature",
        description: "Signature is our original style with warm colors, rich stains, and timeless features.",
    },
];

const detailsTabImages: AttachmentDTO[] = [
    {
        title: "Distinctive Details",
        url: detailsAtEveryTurnImage,
        description: "Each style has high-end details at every turn.",
    },
    {
        title: "Distinctive Details",
        url: floorDetailsImage,
        description: "From wood floors and tall baseboards…",
    },
    {
        title: "Distinctive Details",
        url: ceilingDetailsImage,
        description: "To high ceilings with beams and crown molding…",
    },
    {
        title: "Distinctive Details",
        url: everythingBetweenDetailsImage,
        description: "And everything in between!",
    },
];

const yourStyleTabImages: AttachmentDTO[] = [
    {
        url: howToChooseImage,
        title: "How to Choose?",
        description: "We recommend picking the style that best matches your overall color palette.",
    },
    {
        url: graysAndWhitesImage,
        title: "Transitional = Grays & Whites",
        description: "For grays and whites, Transitional is the best canvas and provides nearly limitless flexibility.",
    },
    {
        url: beigesAndCreamsImage,
        title: "Signature = Beiges & Creams",
        description: "If warmer colors and darker stains is your aesthetic, Signature is the way to go!",
    },
    {
        url: curatedStylesImage,
        title: "Curated Styles",
        description:
            "The finishes in each style have been carefully chosen to complement that style. For the best value, we recommend sticking with one style but we do offer the ability to mix and match some features.",
    },
];

export function StylesPage() {
    const overviewBlockRef = useRef<HTMLDivElement>(null);
    const compareBlockRef = useRef<HTMLDivElement>(null);
    const styleBlocksRefs = useRef<(HTMLDivElement | null)[]>([]);

    const [isPropertySummaryHeaderVisible, setPropertySummaryHeaderVisible] = useState<boolean>(false);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>();

    const navigate = useNavigate();
    const { property, styles: styleObjects } = usePropertyFlow();
    const { parameters, parametersAsString } = useQueryParameters();
    const hasProperty = parameters[WizardQueryParameterKeys.PROPERTY] || parameters[WizardQueryParameterKeys.OWNERS_LAND];
    const { screenWidth } = useScreenSize();
    const isDesktop = screenWidth > HorizontalBreakpoint.MEDIUM;

    useEffect(() => {
        if (styleObjects.length === 0) {
            return;
        }

        // After style objects is grabbed check scroll Y to cause rerender for tab navigator
        checkScrollY();
    }, [styleObjects]);

    // If hasProperty changes value, scroll to top of the page
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [hasProperty]);

    //#region Util Function
    function checkScrollY() {
        // Check if the container scroll position is greater than 0
        const scrolled = window.scrollY > 0;
        // Update property header visibility based on the scroll position
        setPropertySummaryHeaderVisible(scrolled);

        const currentStyleIndex = styleBlocksRefs.current.findIndex(
            (styleRef) => styleRef && window.scrollY < styleRef.offsetTop + styleRef.offsetHeight && window.scrollY >= styleRef.offsetTop
        );

        setCurrentPageIndex(currentStyleIndex);
    }

    const getStyleSelectUrl = useCallback((styleName: string) => {
        const styleQueryParameter = `${WizardQueryParameterKeys.STYLE}=${styleName}`;
        const queryParameters = getCombinedQueryParameters(parametersAsString, styleQueryParameter);
        const url = getRouteUrl(AppRoute.FeaturesAndUpgrades, queryParameters);
        return url;
    }, []);
    //#endregion

    function onStyleSelect(selectedStyleName: string) {
        const url = getStyleSelectUrl(selectedStyleName);
        navigate(url);
    }

    //#region Render Functions
    const getMobileTabNavigator = useCallback(() => {
        const navigationTabs: NavigationTab[] = [];

        if (overviewBlockRef.current) {
            navigationTabs.push({ blockElement: overviewBlockRef.current, name: "Overview" });
        }

        if (compareBlockRef.current) {
            navigationTabs.push({ blockElement: compareBlockRef.current, name: "Compare" });
        }

        styleObjects.forEach((styleObject, index) => {
            const styleBlockRef = styleBlocksRefs.current[index];

            if (styleBlockRef) {
                navigationTabs.push({ blockElement: styleBlockRef, name: styleObject.name });
            }
        });

        return <MobilePageTabNavigator navigationTabs={navigationTabs} />;
    }, [styleObjects]);

    const styleButtons = useMemo(() => {
        const styleButtons = styleObjects.map((styleObject, index) => {
            // If we are on this section set button style to default, otherwise it should be transparent
            const buttonStyle = index === currentPageIndex ? ButtonStyle.DEFAULT : ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER;

            return (
                <BaseButton key={index} buttonStyle={buttonStyle} href={getStyleSelectUrl(styleObject.name)}>
                    Select {styleObject.name}
                </BaseButton>
            );
        });

        return styleButtons;
    }, [styleObjects, currentPageIndex, getStyleSelectUrl]);

    const additionalHeader = useMemo(() => {
        if (isDesktop) {
            if (!hasProperty) {
                return;
            }

            return <PropertySummaryHeader buttons={styleButtons} isHidden={!isPropertySummaryHeaderVisible} property={property} />;
        }

        return getMobileTabNavigator();
    }, [isPropertySummaryHeaderVisible, property, currentPageIndex, getMobileTabNavigator]);

    function getStylesPagePreFooter() {
        if (!hasProperty) {
            return;
        }

        return <StylesPagePreFooter styleObjects={styleObjects} getStyleSelectUrl={getStyleSelectUrl} />;
    }

    function getTitle() {
        if (!hasProperty) {
            return "Our Styles";
        }

        return "Select Your Style";
    }

    function getDetailsBlockDescription() {
        if (!hasProperty) {
            return "Our two distinctive styles, Signature and Transitional, feature impeccable craftsmanship, unmatched attention to detail, and are loaded with features that most builders consider to be costly upgrades.";
        }

        return <PropertyInlineInfo property={property} />;
    }

    function getTwoStylesTab(): TabPair {
        const tabIcon = <SelectionsIcon />;
        const tabTitle = isDesktop ? "Two Distinctive Styles" : "Two Styles";
        const tabContent = <DetailsBlockAttachmentCarousel attachments={twoStylesTabImages} />;

        return {
            tabIcon,
            tabTitle,
            tabContent,
        };
    }

    function getDetailsTab(): TabPair {
        const tabIcon = <PhotosIcon />;
        const tabTitle = isDesktop ? "Detail After Detail" : "Details";
        const tabContent = <DetailsBlockAttachmentCarousel attachments={detailsTabImages} />;

        return {
            tabIcon,
            tabTitle,
            tabContent,
        };
    }

    function getYourStyleTab(): TabPair {
        const tabIcon = <PaintBrushIcon strokeColor="var(--executive-blues-80)" secondaryStrokeColor="var(--seafoam-green)" />;
        const tabTitle = isDesktop ? "Choosing Your Style" : "Your Style";
        const tabContent = <DetailsBlockAttachmentCarousel attachments={yourStyleTabImages} />;

        return {
            tabIcon,
            tabTitle,
            tabContent,
        };
    }

    function getDetailsBlockTabs(): TabPair[] {
        const twoStylesTab = getTwoStylesTab();
        const detailsTab = getDetailsTab();
        const yourStyleTab = getYourStyleTab();

        return [twoStylesTab, detailsTab, yourStyleTab];
    }

    const bottomButtonSection = useMemo(() => {
        if (!hasProperty) {
            return;
        }

        const selectStyleButtons = styleObjects.map((styleObject) => (
            <BaseButton
                key={styleObject.name}
                className={styles.baseButton}
                buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                hasChevron={isDesktop}
                href={getStyleSelectUrl(styleObject.name)}
            >
                Select {styleObject.name}
            </BaseButton>
        ));

        return <div className={styles.detailsBlockButtonsWrapper}>{selectStyleButtons}</div>;
    }, [styleObjects, hasProperty, getStyleSelectUrl]);

    function getStylesBlocksWithSeparators() {
        const styleBlocksAndSeparators: ReactElement[] = [];

        styleObjects.forEach((styleObject, index) => {
            styleBlocksAndSeparators.push(<MobileBlockSeparator key={`separator-${index}`} />);
            styleBlocksAndSeparators.push(
                <StyleBlock
                    ref={(ref) => (styleBlocksRefs.current[index] = ref)}
                    key={`block-${index}`}
                    styleObject={styleObject}
                    getStyleSelectUrl={getStyleSelectUrl}
                />
            );
        });

        return styleBlocksAndSeparators;
    }

    function getMobileStickyFooterBottom() {
        return <div className={styles.mobileStickyFooterBottom}>{styleButtons}</div>;
    }

    function getMobileStickyFooter() {
        if (isDesktop || !hasProperty) {
            return;
        }

        return <MobilePropertySummaryFooter property={property} bottomSection={getMobileStickyFooterBottom()} />;
    }

    function getPageTitleHeader() {
        if (isDesktop) {
            return;
        }

        const title = hasProperty ? "Select Your Style" : "Our Styles";
        return <HorizontalSectionHeader title={title} />;
    }
    //#endregion

    // If no floorPlan yet, assume we are still fetching from the api so do not return anything to prevent placeholder flickers
    if (!property.floorPlan && hasProperty) {
        return null;
    }

    const onStyleSelectPropValue = hasProperty ? onStyleSelect : undefined;

    // Only apply classes to mobile since we don't want to override desktop default height
    const detailsBlockClasses = isDesktop ? undefined : classNames(styles.detailsBlock, !hasProperty && styles.fullScreen);

    return (
        <Page className={styles.root} onPageScroll={checkScrollY} preFooterContent={getStylesPagePreFooter()}>
            {getPageTitleHeader()}
            {additionalHeader}
            <DetailsBlock
                className={detailsBlockClasses}
                ref={overviewBlockRef}
                title={getTitle()}
                description={getDetailsBlockDescription()}
                tabs={getDetailsBlockTabs()}
                bottomSection={bottomButtonSection}
                showBottomOnMobile={false}
            />
            <MobileBlockSeparator />
            <StylesComparisonBlock ref={compareBlockRef} styleObjects={styleObjects} onStyleSelect={onStyleSelectPropValue} />
            {getStylesBlocksWithSeparators()}
            {getMobileStickyFooter()}
        </Page>
    );
}
