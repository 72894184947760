import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { DetailsBlockAttachmentSlide } from "../../carousels/details-block-attachment-carousel/details-block-attachment-slide";
import { ChevronIcon } from "../../icons/chevron-icon";

import styles from "./style-comparison-card.module.scss";
import classNames from "classnames";

export type StyleComparisonCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The style to make the card for
     */
    attachment?: AttachmentDTO;
    /**
     * The name of the style this is for
     */
    styleName?: string;
    /**
     * The style category to show the attachment for
     */
    selectedCategory?: string;
    /**
     * Function to select this style
     */
    onStyleSelect?: (styleName: string) => void;
    /**
     * Function to call to capture the mouse down event
     */
    onMouseDownCapture?: () => void;
};

export function StyleComparisonCard({
    className,
    attachment,
    styleName,
    selectedCategory,
    onStyleSelect,
    onMouseDownCapture,
}: StyleComparisonCardProps) {
    function onClick() {
        if (!styleName || !onStyleSelect) {
            return;
        }

        onStyleSelect(styleName);
    }

    const isClickable = !!onStyleSelect;
    const onClickPropValue = isClickable ? onClick : undefined;

    const classes = classNames(styles.root, isClickable && styles.clickable, className);

    return (
        <div key={selectedCategory} className={classes} onClick={onClickPropValue} onMouseDownCapture={onMouseDownCapture}>
            <DetailsBlockAttachmentSlide key={selectedCategory} className={styles.imageSlide} attachment={attachment} />
            <div className={styles.styleSelectButton}>
                {styleName}
                {isClickable && <ChevronIcon className={styles.chevronIcon} />}
            </div>
        </div>
    );
}
