import { useState } from "react";

import { OverviewBlock } from "../../components/blocks/overview-block";
import { WarrantyBlock } from "../../components/blocks/warranty-block";
import { BaseButton, ButtonStyle } from "../../components/buttons/base-button";
import { LinkWrapper } from "../../components/links/link-wrapper";
import { PageTitleImage } from "../../components/misc/page-title-image";
import { WarrantyModal } from "../../components/modals/warranty-modal";
import { Constants } from "../../utilities/Constants";
import { Orientation } from "../../utilities/enums/Orientation";
import { Page } from "../page";

import styles from "./warranty-page.module.scss";

import fifthWarrantyImage from "../../assets/pages/warranty-page/FifthWarrantyImage.webp";
import firstWarrantyImage from "../../assets/pages/warranty-page/FirstWarrantyImage.jpg";
import fourthWarrantyImage from "../../assets/pages/warranty-page/FourthWarrantyImage.jpg";
import secondWarrantyImage from "../../assets/pages/warranty-page/SecondWarrantyImage.webp";
import thirdWarrantyImage from "../../assets/pages/warranty-page/ThirdWarrantyImage.webp";
import warrantyBannerImage from "../../assets/pages/warranty-page/WarrantyBannerImage.webp";

export function WarrantyPage() {
    const [showWarrantyModal, setShowWarrantyModal] = useState<boolean>(false);

    const content = (
        <div>
            Welcome to Executive Homes’ Warranty Submission Portal! Below, you will find information about Executive’s warranty program and
            a link to submit a request for warranty service. If you have any difficulty submitting your warranty request or questions about
            the warranty program, please do not hesitate to contact our Warranty Department, which can be reached at&nbsp;
            <LinkWrapper className={styles.link} to={Constants.WARRANTY_EMAIL_MAIL_LINK}>
                {Constants.WARRANTY_EMAIL}
            </LinkWrapper>
            .
        </div>
    );

    return (
        <Page className={styles.root}>
            <PageTitleImage image={warrantyBannerImage} title="EH Warranty" />
            <OverviewBlock titleText="WARRANTY DETAILS" content={content} />
            <WarrantyBlock
                className={styles.warrantyBlock}
                orientation={Orientation.LEFT}
                imageSrc={firstWarrantyImage}
                titleText="DEFECT-FREE GUARANTEE"
                contentText="Each of our homes are backed by our one-year express warranty, which GUARANTEES it will be free from defects for one-year after closing. Additionally, our skilled and responsive warranty team is standing by seven days a week to provide assistance or answer any questions you may have after moving into your new home."
            />
            <WarrantyBlock
                className={styles.warrantyBlock}
                orientation={Orientation.RIGHT}
                imageSrc={secondWarrantyImage}
                titleText="QUICK & RELIABLE SERVICE"
                contentText="Our warranty team is dedicated to providing each of our homeowners the best possible new home experience.  Our warranty specialist has more than 20-years of construction experience and travels in a fully-outfitted mobile workshop to help expedite the repairs of any issues you may experience."
            />
            <WarrantyBlock
                className={styles.warrantyBlock}
                orientation={Orientation.LEFT}
                imageSrc={thirdWarrantyImage}
                titleText="WHAT’S COVERED?"
                contentText="Executive’s warranty largely incorporates the standards established by the National Home Builders Association and Oklahoma State Home Builders Association. Incorporating these objective national and local standards helps provide each of our homeowners with predictable warranty determinations and reliable service."
            />
            <WarrantyBlock
                className={styles.warrantyBlock}
                orientation={Orientation.RIGHT}
                imageSrc={fourthWarrantyImage}
                titleText="WHEN TO SCHEDULE SERVICE?"
                contentText="The materials in every home require several months to acclimate to their new environment. During this time, it is very common to experience non-emergency warranty issues, such as door adjustments and tile cracks. For this reason, we ask each homeowner to submit all non-emergency warranty claims sometime during the final month of their one-year warranty period."
            />
            <WarrantyBlock
                className={styles.warrantyBlock}
                orientation={Orientation.LEFT}
                imageSrc={fifthWarrantyImage}
                titleText="EMERGENCY WARRANTY SERVICE"
                contentText="There are a few items that we certainly don’t want you to wait until the end of the warranty to correct. The Emergency Warranty Service items include: HVAC failure, plumbing leaks, or electrical malfunctions. In the event any of those issues occur, please directly contact the applicable contractor listed on page 2 of your warranty manual."
            />

            <div className={styles.submitRequestButtonWrapper}>
                <BaseButton buttonStyle={ButtonStyle.DEFAULT} hasChevron={true} onClick={() => setShowWarrantyModal(true)}>
                    Submit Request
                </BaseButton>
            </div>

            <WarrantyModal isOpen={showWarrantyModal} onCloseModal={() => setShowWarrantyModal(false)} />
        </Page>
    );
}
