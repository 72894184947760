import { Orientation } from "../../../utilities/enums/Orientation";
import { BaseIcon, BaseIconProps } from "../base-icon";

export interface ArrowIconProps extends BaseIconProps {
    /**
     * The orientation the arrow is facing
     */
    arrowDirection?: Orientation.UP | Orientation.DOWN;
}

export function ArrowIcon({
    arrowDirection = Orientation.DOWN,
    strokeColor = "var(--seafoam-green)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    fillColor = "none",
    ...baseIconProps
}: ArrowIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 4 10"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            {...baseIconProps}
        >
            <path d="M2 0.5 2 9.5" />
            {arrowDirection === Orientation.UP && <path d="M0.5 2 2 0.5 3.5 2" />}
            {arrowDirection === Orientation.DOWN && <path d="M0.5 8 2 9.5 3.5 8" />}
        </BaseIcon>
    );
}
