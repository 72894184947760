import { useCallback, useRef } from "react";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { Orientation } from "../../../utilities/enums/Orientation";

type NextStepsSvgMaskProps = {
    /**
     * The id of the mask to be referenced by the element using it
     */
    id?: string;
    /**
     * Classname to apply to the svg element
     */
    className?: string;
    /**
     * The border radius of the rectangles
     * @default 16
     */
    borderRadius?: number;
    /**
     * The number of rectangles to render
     * @default 3
     */
    rectCount?: number;
    /**
     * The gap between rectangles
     * @default 8
     */
    gap?: number;
    /**
     * The orientation of the rectangles
     * @default Orientation.ROW
     */
    orientation?: Orientation.ROW | Orientation.COLUMN;
};

export function NextStepsSvgMask({
    id,
    className,
    borderRadius = 16,
    rectCount = 3,
    gap = 8,
    orientation = Orientation.ROW,
}: NextStepsSvgMaskProps) {
    const svgRef = useRef<SVGSVGElement>(null);
    const { screenWidth } = useScreenSize();

    const getRectProperties = useCallback(
        (index: number) => {
            if (orientation === Orientation.ROW) {
                const svgWidth = svgRef.current?.getBoundingClientRect().width || 0;
                const width = (svgWidth - (rectCount - 1) * gap) / rectCount;
                const x = index * (width + gap);

                return { x, y: 0, width, height: "100%" };
            }

            //assuming column orientation
            const svgHeight = svgRef.current?.getBoundingClientRect().height || 0;
            const height = (svgHeight - (rectCount - 1) * gap) / rectCount;
            const y = index * (height + gap);

            return { x: 0, y, width: "100%", height };
        },
        [screenWidth, rectCount, gap]
    );

    function getRectangles() {
        return Array.from({ length: rectCount }, (_, index) => {
            const props = getRectProperties(index);

            return (
                <rect
                    key={index}
                    x={props.x}
                    y={props.y}
                    width={props.width}
                    height={props.height}
                    rx={borderRadius}
                    ry={borderRadius}
                    fill="white"
                />
            );
        });
    }

    return (
        <svg ref={svgRef} className={className} width="100%" height="100%">
            <defs>
                <mask id={id} x="0" y="0" width="100%" height="100%">
                    {getRectangles()}
                </mask>
            </defs>
        </svg>
    );
}
