import { ReactElement } from "react";

import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { BaseCarousel } from "../base-carousel";
import { CarouselArrowStyle, CarouselDotsStyle } from "../base-carousel/base-carousel";
import { DetailsBlockAttachmentSlide } from "./details-block-attachment-slide";
import { DetailsBlockAttachmentSlideWithFullScreenGallery } from "./details-block-attachment-slide/details-block-attachment-slide-with-fullscreen-gallery";

import styles from "./details-block-attachment-carousel.module.scss";
import classNames from "classnames";

export type DetailsBlockAttachmentCarouselProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether we should have extra padding on mobile to account for overlap with bottom blocks
     * @default false
     */
    extraBottomPaddingOnMobile?: boolean;
    /**
     * A flag to determine if the full screen gallery should open when the content is click
     * The full screen gallery is used to display the gallery in full screen mode
     * It will be a vertical list scroller in mobile and a horizontal slider in desktop
     */
    hasFullScreenGallery?: boolean;
    /**
     * Whether the carousel should be infinite or not
     * @default true
     */
    infinite?: boolean;
    /**
     * Attachments to display
     */
    attachments?: AttachmentDTO[];
    /**
     * The sales attachments to display as slides
     */
    salesAttachments?: AttachmentDTO[];
    /**
     * The slides to display before attachments
     */
    slidesBeforeAttachments?: ReactElement[];
};

export function DetailsBlockAttachmentCarousel({
    className,
    extraBottomPaddingOnMobile = false,
    hasFullScreenGallery,
    infinite = true,
    attachments = [],
    salesAttachments = [],
    slidesBeforeAttachments = [],
}: DetailsBlockAttachmentCarouselProps) {
    const { screenWidth } = useScreenSize();

    function getAllSlides() {
        const nodes = [...slidesBeforeAttachments];

        salesAttachments.forEach((attachment, index) =>
            nodes.push(
                <DetailsBlockAttachmentSlide
                    key={index}
                    attachment={attachment}
                    isSalesAttachment={true}
                    extraBottomPaddingOnMobile={extraBottomPaddingOnMobile}
                />
            )
        );
        attachments.forEach((attachment, index) =>
            nodes.push(
                <DetailsBlockAttachmentSlide key={index} attachment={attachment} extraBottomPaddingOnMobile={extraBottomPaddingOnMobile} />
            )
        );

        return nodes;
    }

    function getAttachmentDisplay() {
        if (attachments.length === 0 && salesAttachments.length === 0 && slidesBeforeAttachments.length === 1) {
            return slidesBeforeAttachments;
        }

        // If only one sales attachment display that sales attachment without a carousel with sales style
        if (salesAttachments.length === 1 && attachments.length === 0 && slidesBeforeAttachments.length === 0) {
            if (hasFullScreenGallery) {
                return <DetailsBlockAttachmentSlideWithFullScreenGallery attachment={salesAttachments[0]} isSalesAttachment={true} />;
            }

            return <DetailsBlockAttachmentSlide attachment={salesAttachments[0]} isSalesAttachment={true} />;
        }

        // If only one attachment display that attachment without a carousel
        if (attachments.length === 1 && salesAttachments.length === 0 && slidesBeforeAttachments.length === 0) {
            if (hasFullScreenGallery) {
                return <DetailsBlockAttachmentSlideWithFullScreenGallery attachment={attachments[0]} />;
            }

            return <DetailsBlockAttachmentSlide attachment={attachments[0]} />;
        }

        const slides = getAllSlides();
        const showSlideNumber = slides.length > 1;
        const dotsClassName = extraBottomPaddingOnMobile ? styles.extraBottomDots : undefined;

        return (
            <BaseCarousel
                className={styles.attachmentCarousel}
                arrowsClassName={styles.arrowOpacity}
                dotsClassName={dotsClassName}
                arrowsStyle={CarouselArrowStyle.LARGE_INTERIOR_ARROWS}
                arrows={!isMobile}
                dots={isMobile}
                dotsStyle={CarouselDotsStyle.INTERIOR_DOTS}
                infinite={infinite}
                showSlideNumber={showSlideNumber}
                numberOfSlidesToPreload={3}
                hasFullScreenGallery={hasFullScreenGallery}
            >
                {slides}
            </BaseCarousel>
        );
    }

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
    const classes = classNames(styles.root, className);

    return <div className={classes}>{getAttachmentDisplay()}</div>;
}
