import { useState } from "react";

import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { BaseCarousel } from "../../carousels/base-carousel";
import { CarouselArrowStyle } from "../../carousels/base-carousel/base-carousel";

import styles from "./floor-plan-level-card-display.module.scss";
import classNames from "classnames";

export type FloorPlanLevelCardDisplayProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The floor plan to display
     */
    floorPlan?: FloorPlanDTO;
    /**
     * Whether the display is for the bottom half of the card rather than the right
     */
    isBottomHalf?: boolean;
};

export function FloorPlanLevelCardDisplay({ className, floorPlan, isBottomHalf }: FloorPlanLevelCardDisplayProps) {
    const [showArrows, setShowArrows] = useState<boolean>(false);

    const { screenWidth } = useScreenSize();

    //#region Render Functions
    function getSingleImageDisplay() {
        const classes = classNames(styles.root, isBottomHalf && styles.bottomHalfCard, className);

        return (
            <div className={classes}>
                <img className={styles.singleImage} src={images[0].url} />
            </div>
        );
    }

    function getCarouselWrapperProps() {
        if (isMobile) {
            return;
        }

        // Only give onMouseEnter/Leave on desktop or else mobile will treat first tap as onMouseEnter
        // Making onClick feel laggy since it takes two clicks often
        return {
            onMouseEnter: () => setShowArrows(true),
            onMouseLeave: () => setShowArrows(false),
        };
    }

    function getCarouselDisplay() {
        const classes = classNames(styles.root, showArrows && styles.showArrows, isBottomHalf && styles.bottomHalfCard, className);

        return (
            // Stop onClick Propagation on carousel wrapping div to stop parent onClick messing up carousel functionality
            <div className={classes} {...getCarouselWrapperProps()}>
                <BaseCarousel
                    className={styles.imageCarousel}
                    arrowsClassName={styles.carouselArrows}
                    arrowsStyle={CarouselArrowStyle.INTERIOR_ARROWS}
                    arrows={true}
                    dots={true}
                >
                    {images.map((image, index) => (
                        <img key={index} src={image.url} className={styles.imageInSlide} />
                    ))}
                </BaseCarousel>
            </div>
        );
    }
    //#endregion

    if (!floorPlan || !floorPlan.images || floorPlan.images.length === 0) {
        return null;
    }

    const { images } = floorPlan;
    const hasMoreThanOneImage = images.length > 1;
    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;

    return hasMoreThanOneImage ? getCarouselDisplay() : getSingleImageDisplay();
}
