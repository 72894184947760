import { useState } from "react";

import { Orientation } from "../../../utilities/enums/Orientation";
import { ChevronIcon } from "../../icons/chevron-icon";

import styles from "./expand-section-toggle.module.scss";
import classNames from "classnames";

export type ExpandSectionToggleProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Text to display when opened
     */
    openedText?: string;
    /**
     * Text to display when closed
     */
    closedText?: string;
    /**
     * Event to do when it is clicked open or closed
     */
    onClick?: (expanded: boolean) => void;
};

export function ExpandSectionToggle({ className, openedText, closedText, onClick }: ExpandSectionToggleProps) {
    const [isOpen, setOpen] = useState<boolean>(false);

    function onButtonClick() {
        setOpen(!isOpen);
        if (onClick) {
            onClick(!isOpen);
        }
    }

    const buttonText = isOpen ? openedText : closedText;
    const arrowDirection = isOpen ? Orientation.UP : Orientation.DOWN;

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <hr className={styles.outerLine} />
            <button className={styles.centerButton} onClick={onButtonClick}>
                <div className={styles.buttonText}>{buttonText}</div>
                <ChevronIcon className={styles.chevronIcon} arrowDirection={arrowDirection} />
            </button>
            <hr className={styles.outerLine} />
        </div>
    );
}
