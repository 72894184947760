import { ReactNode, useEffect } from "react";

import { useLocation } from "react-router-dom";

import { capitalizeFirstLetterOfEachWord } from "../formatting/capitalizeFirstLetterOfEachWord";
import { AppRoute, JsonField, RouteInfo, getNestedObjects, getPathForRoute } from "./AppRoute";
import { isOnAmenitiesPath } from "./utils/isOnAmenitiesPath";
import { isOnBrowsePath } from "./utils/isOnBrowsePath";
import { isOnFloorPlansPath } from "./utils/isOnFloorPlansPath";
import { isOnNeighborhoodPath } from "./utils/isOnNeighborhoodPath";
import { isOnNeighborhoodsPath } from "./utils/isOnNeighborhoodsPath";
import { isOnOurHomesPath } from "./utils/isOnOurHomesPath";
import { isOnPropertyPath } from "./utils/isOnPropertyPath";

/**
 * Prepends a page title to the " | Executive Homes"
 * and sets it to the document title
 * @param title
 */
function setPageTitle(title: string) {
    const isHomePage = title === "";

    if (isHomePage) {
        document.title = `Executive Homes | Building Distinction`;
        return;
    }

    document.title = `${title} | Executive Homes`;
}

/**
 * Recursively searches the AppRoute for all available routes in the app
 * @returns RouteInfo[]
 */
function getRoutes(): RouteInfo[] {
    const routes = getAllRoutes(AppRoute);
    return routes;
}

function getAllRoutes(route: JsonField): RouteInfo[] {
    const routes: RouteInfo[] = [];

    const nestedObjects = getNestedObjects(route);
    nestedObjects.forEach((nestedObject) => routes.push(...[nestedObject as RouteInfo, ...getAllRoutes(nestedObject)]));

    return routes;
}

/**
 * Gets the specific RouteInfo object given a path name to check
 * @param path The path to check against
 * @returns The RouteInfo
 */
function getRouteInfoFromPath(path: string): RouteInfo {
    path = path.toLowerCase();
    const routes = getRoutes();

    const foundRoute = routes.find((route) => {
        const pathFromRoute = getPathForRoute(route);

        if (pathFromRoute.includes("/:")) {
            const pathFromRouteWithoutWildcard = pathFromRoute.split("/:")[0];
            let pathSplits = path.split("/");

            if (pathSplits.length > 2) {
                // Remove the wildcard element
                pathSplits = pathSplits.slice(0, -1);
            }

            const pathWithoutWildcard = pathSplits.join("/");

            return pathFromRouteWithoutWildcard === pathWithoutWildcard;
        }

        return pathFromRoute === path;
    });

    if (!foundRoute) {
        throw new Error(`Could not get route info from path: ${path}`);
    }

    return foundRoute;
}

/**
 * Verifies that the path is a valid path in the routing mapping
 * @param path The path to check if it is valid
 * @returns true/false ifPathIsValid
 */
function checkIfPathIsValid(path: string): boolean {
    path = path.toLowerCase();

    try {
        const pathIsValid: boolean = !!getRouteInfoFromPath(path);
        return pathIsValid;
    } catch {
        return false;
    }
}

/**
 * Given a RouteInfo object, uses that data to set the page title and the browser's history state
 * @param routeInfo The route info to pull data from and set for the page
 */
function handleTitle(currentPath: string, routeInfo: RouteInfo) {
    const pageTitle = routeInfo.name;
    const parameterInRoute = currentPath.split("/")[2];

    const isPropertyPath = isOnPropertyPath(currentPath);

    if (!parameterInRoute || routeInfo.slug === AppRoute.RouteNotFound.slug) {
        if (isPropertyPath) {
            setPageTitle("Owner's Land");
            return;
        }

        setPageTitle(pageTitle);
        return;
    }

    const cleanName = capitalizeFirstLetterOfEachWord(parameterInRoute);

    if (isPropertyPath || isOnNeighborhoodPath(currentPath)) {
        setPageTitle(cleanName);
        return;
    }

    if (isOnBrowsePath(currentPath)) {
        const pageTitleWithMarketName = `Browse ${cleanName}`;
        setPageTitle(pageTitleWithMarketName);
        return;
    }

    if (isOnOurHomesPath(currentPath)) {
        const pageTitleWithMarketName = pageTitle.replace("Our", cleanName);
        setPageTitle(pageTitleWithMarketName);
        return;
    }

    if (isOnFloorPlansPath(currentPath)) {
        const pageTitleWithMarketName = `${cleanName} Floor Plans`;
        setPageTitle(pageTitleWithMarketName);
        return;
    }

    if (isOnNeighborhoodsPath(currentPath)) {
        const pageTitleWithMarketName = `Neighborhoods In ${cleanName}`;
        setPageTitle(pageTitleWithMarketName);
        return;
    }

    if (isOnAmenitiesPath(currentPath)) {
        const pageTitleWithMarketName = `Amenities In ${cleanName}`;
        setPageTitle(pageTitleWithMarketName);
        return;
    }

    throw new Error("Refusing to set page title to a default, this should not happen");
}

export type RoutingHandlerProps = {
    children: ReactNode;
};

export function RoutingHandler({ children }: RoutingHandlerProps) {
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        const isValidPath = checkIfPathIsValid(currentPath);

        const routeInfo = isValidPath ? getRouteInfoFromPath(currentPath) : AppRoute.RouteNotFound;
        handleTitle(currentPath, routeInfo);
    }, [location]);

    return <>{children}</>;
}
