import { SVGProps, useEffect, useRef } from "react";

import styles from "./progress-circle-icon.module.scss";

interface ProgressCircleIconProps {
    /**
     * The diameter of the circle in pixels
     * @default 48
     */
    size?: number;
    /**
     * The color of the stroke
     */
    strokeColor?: string;
    /**
     * The width of the stroke
     */
    strokeWidth?: number;
    /**
     * The secondary color of the stroke
     */
    secondaryStrokeColor?: string;
    /**
     * function to get the progress of the video
     * @returns number 0 - 1
     */
    getProgress?: () => number;
    /**
     * Onclick event handler
     */
    onClick?: () => void;
}

export function ProgressCircleIcon({
    size = 48,
    strokeColor = "var(--seafoam-green)",
    strokeWidth = 2,
    secondaryStrokeColor = "var(--secondary-40)",
    getProgress,
    onClick,
}: ProgressCircleIconProps) {
    const progressCircleRef = useRef<SVGCircleElement>(null);

    const chevronPath = `M${size / 2 - 9}, ${size / 2 - 4} L${size / 2}, ${size / 2 + 4} L${size / 2 + 9}, ${size / 2 - 4}`;
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const commonPathProps: SVGProps<SVGCircleElement> = {
        fill: "none",
        strokeWidth: strokeWidth,
        strokeLinecap: "round",
    };
    const commonCircleProps: SVGProps<SVGCircleElement> = {
        cx: size / 2,
        cy: size / 2,
        r: radius,
        ...commonPathProps,
    };

    useEffect(() => {
        const animationFrame = requestAnimationFrame(updateProgress);

        return () => {
            cancelAnimationFrame(animationFrame);
        };
    }, [circumference, getProgress]);

    function updateProgress() {
        const progress = getProgress ? getProgress() : 0;
        const percent = progress / 100;
        const offset = circumference - percent * circumference;

        if (progressCircleRef.current) {
            progressCircleRef.current.style.strokeDashoffset = offset.toString();
        }

        requestAnimationFrame(updateProgress);
    }

    return (
        <svg viewBox={`0 0 ${size} ${size}`} className={styles.root} width={size} height={size} onClick={onClick}>
            <circle stroke={secondaryStrokeColor} {...commonCircleProps} />
            <circle
                ref={progressCircleRef}
                stroke={strokeColor}
                strokeDasharray={circumference}
                transform={`rotate(-90 ${size / 2} ${size / 2})`}
                {...commonCircleProps}
            />
            <path stroke={secondaryStrokeColor} d={chevronPath} {...commonPathProps} />
        </svg>
    );
}
