import { forwardRef, useState } from "react";

import { IconTabCarousel } from "../../../../carousels/icon-tab-carousel";
import { IconTab } from "../../../../tabs/icon-tab";
import { DetailsBlockProps, TabPair } from "../details-block";

import styles from "./mobile-details-block.module.scss";
import classNames from "classnames";

export const MobileDetailsBlock = forwardRef(
    (
        {
            className,
            mobileTabCarouselClassName,
            isInModal = false,
            preTitle,
            title,
            description,
            underHeaderDividerSection,
            tabs = [],
            bottomSection,
            showBottomOnMobile,
        }: DetailsBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

        //#region Render Functions
        //#region Tab Content
        function getTabContent(tab: TabPair, index: number) {
            const wrapperClass = classNames(styles.tabWrapper, index !== currentTabIndex && styles.hiddenTab, isInModal && styles.rounded);

            return (
                <div key={index} className={wrapperClass}>
                    {tab.tabContent}
                </div>
            );
        }

        function getTabContents() {
            return tabs.map(getTabContent);
        }
        //#endregion

        //#region Icon Tabs
        function getIconTab(tab: TabPair, index: number) {
            const isSelected = currentTabIndex === index;
            const onClick = () => setCurrentTabIndex(index);

            return <IconTab key={index} icon={tab.tabIcon} name={tab.tabTitle} isSelected={isSelected} onClick={onClick} />;
        }

        function getIconTabs() {
            return tabs.map(getIconTab);
        }
        //#endregion
        //#endregion

        const classes = classNames(styles.root, isInModal && styles.modal, !showBottomOnMobile && styles.padBottom, className);
        const mobileTabCarouselClasses = classNames(styles.iconCarousel, mobileTabCarouselClassName);

        return (
            <div ref={ref} className={classes}>
                <IconTabCarousel className={mobileTabCarouselClasses}>{getIconTabs()}</IconTabCarousel>
                <div className={styles.tabsContentWrapper}>{getTabContents()}</div>
                {showBottomOnMobile && (
                    <div className={styles.bottomContentWrapper}>
                        {preTitle && <div className={styles.preTitle}>{preTitle}</div>}
                        <div className={styles.title}>{title}</div>
                        {description && <div className={styles.descriptionWrapper}>{description}</div>}
                        {underHeaderDividerSection && <div className={styles.additionalStatsWrapper}>{underHeaderDividerSection}</div>}
                        {bottomSection && <div>{bottomSection}</div>}
                    </div>
                )}
            </div>
        );
    }
);
