import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { getAmenitiesUrl } from "../../../utilities/urls/getAmenitiesUrl";
import { getBrowseUrl } from "../../../utilities/urls/getBrowseUrl";
import { getFloorPlansUrl } from "../../../utilities/urls/getFloorPlansUrl";
import { getNeighborhoodsUrl } from "../../../utilities/urls/getNeighborhoodsUrl";
import { FindYourHomeCard } from "../../cards/find-your-home-card";
import { BaseCarousel } from "../../carousels/base-carousel";
import { VerticalSectionHeader } from "../../headers/vertical-section-header";

import styles from "./find-your-home-block.module.scss";
import classNames from "classnames";

import allLotsAndHomesImage from "../../../assets/components/find-your-home-block/AllLotsAndHomesImage.png";
import floorPlansImage from "../../../assets/components/find-your-home-block/FloorPlansImage.png";
import moveInReadyImage from "../../../assets/components/find-your-home-block/MoveInReadyImage.png";
import myOwnLandImage from "../../../assets/components/find-your-home-block/MyOwnLandImage.png";
import neighborhoodAmenitiesImage from "../../../assets/components/find-your-home-block/NeighborhoodAmenitiesImage.png";
import schoolDistrictsImage from "../../../assets/components/find-your-home-block/SchoolDistrictsImage.png";

export type FindYourHomeBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

const CAROUSEL_PADDING = 32;
const CARD_WIDTH = 300;
const GAP_BETWEEN = 32;

export function FindYourHomeBlock({ className }: FindYourHomeBlockProps) {
    const { screenWidth } = useScreenSize();

    function getCards() {
        let i = 0;
        const cards = [
            <FindYourHomeCard
                key={i++}
                backgroundImage={allLotsAndHomesImage}
                salesText="Let The Wizard Guide You"
                title="All Lots & Homes"
                buttonText="Browse Everything"
                url={getBrowseUrl(undefined)}
            />,
            <FindYourHomeCard
                key={i++}
                backgroundImage={moveInReadyImage}
                salesText="Ready In 30 Days Or Less"
                title="Move-In Ready"
                buttonText="Find My Home"
                url={getPathForRoute(AppRoute.Ready)}
            />,
            <FindYourHomeCard
                key={i++}
                backgroundImage={floorPlansImage}
                salesText="Layouts For Every Family"
                title="Floor Plans"
                buttonText="View Floor Plans"
                url={getFloorPlansUrl(undefined)}
            />,
            <FindYourHomeCard
                key={i++}
                backgroundImage={neighborhoodAmenitiesImage}
                salesText="Pools, Parks, Ponds, And More!"
                title="Neighborhood Amenities"
                buttonText="Search By Amenity"
                url={getAmenitiesUrl(undefined)}
            />,
            <FindYourHomeCard
                key={i++}
                backgroundImage={schoolDistrictsImage}
                salesText="Area's Top Schools"
                title="School Districts"
                buttonText="Search By School"
                url={getNeighborhoodsUrl(undefined)}
            />,
            <FindYourHomeCard
                key={i++}
                backgroundImage={myOwnLandImage}
                salesText="Build Almost Everywhere"
                title="My Own Land"
                buttonText="Start Building"
                url={getPathForRoute(AppRoute.OwnersLand)}
            />,
        ];

        return cards;
    }

    function getSlidesPerView() {
        const slideCount = Math.floor((screenWidth - CAROUSEL_PADDING * 2) / (CARD_WIDTH + GAP_BETWEEN));

        // Minimum 1 slide per view
        return Math.max(slideCount, 1);
    }

    function getMaxWidthClass() {
        return styles[`cardCarouselLayout${slidesPerView}`];
    }

    function getCardsBlock() {
        // If you can fit all cards on the screen, don't render carousel
        if (slidesPerView >= cards.length) {
            return <div className={styles.cardsWrapperDiv}>{cards}</div>;
        }

        const isDotsCarousel = slidesPerView <= 1;
        const numberOfSlidesToPreload = isDotsCarousel ? 4 : undefined;
        const slideGap = isMobile ? 16 : 32;
        const maxWidthClass = getMaxWidthClass();
        const carouselClasses = classNames(styles.carousel, maxWidthClass);

        return (
            <BaseCarousel
                className={carouselClasses}
                controls={!isDotsCarousel}
                dots={isDotsCarousel}
                slideGap={slideGap}
                slidesPerView={slidesPerView}
                slidesPerClick={1}
                numberOfSlidesToPreload={numberOfSlidesToPreload}
                showOverflow={isDotsCarousel}
            >
                {cards}
            </BaseCarousel>
        );
    }

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
    const cards = getCards();
    const slidesPerView = getSlidesPerView();

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <VerticalSectionHeader
                className={styles.sectionHeader}
                title="FIND YOUR HOME"
                subtitle="Explore the options below to find the home that fits your family's needs!"
            />
            {getCardsBlock()}
        </div>
    );
}
