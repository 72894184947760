import { ReactNode, useEffect } from "react";

import { APIProvider } from "@vis.gl/react-google-maps";
import { BrowserRouter } from "react-router-dom";

import { Constants } from "../utilities/Constants";
import { RoutingHandler } from "../utilities/routing/RoutingHandler";
import { ApplicationSettingsProvider } from "./data/useApplicationSettings";
import { DataProvider } from "./data/useData";
import { MarketProvider } from "./data/useMarket";
import { PanelsProvider } from "./usePanels";
import { ScreenSizeProvider } from "./useScreenSize";

export type ProvidersProps = {
    children: ReactNode;
};

export function Providers({ children }: ProvidersProps) {
    useEffect(() => {
        const refreshInterval = setInterval(() => {
            window.location.reload(); // Refresh page after 23 hours to refresh api connections
        }, 23 * Constants.MILLISECONDS_IN_HOUR); // 23 hours in milliseconds

        return () => clearInterval(refreshInterval); // Clean up the interval on component unmount
    }, []);

    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    if (!GOOGLE_MAPS_API_KEY) {
        throw new Error("Missing REACT_APP_GOOGLE_MAPS_API_KEY from .env");
    }

    return (
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
            {/* Routing  */}
            <BrowserRouter>
                <RoutingHandler>
                    <DataProvider>
                        <ApplicationSettingsProvider>
                            <MarketProvider>
                                <PanelsProvider>
                                    <ScreenSizeProvider>{children}</ScreenSizeProvider>
                                </PanelsProvider>
                            </MarketProvider>
                        </ApplicationSettingsProvider>
                    </DataProvider>
                </RoutingHandler>
            </BrowserRouter>
        </APIProvider>
    );
}
