import { forwardRef, useMemo } from "react";

import { NeighborhoodDTO, PropertyDTO, SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { useAmenities } from "../../../hooks/data/useAmenities";
import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { getIconForAmenity } from "../../../utilities/mappers/icons/getIconForAmenity";
import { IconTabCarousel } from "../../carousels/icon-tab-carousel";
import { HorizontalSectionHeader } from "../../headers/horizontal-section-header";
import { IconTab } from "../../tabs/icon-tab";
import { EntitySearchBlock } from "../entity-search-block";

import styles from "./amenities-page-search-block.module.scss";
import classNames from "classnames";

export type AmenitiesPageSearchBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the data for the search block is loading
     */
    isLoading?: boolean;
    /**
     * The neighborhoods to display
     */
    neighborhoods?: NeighborhoodDTO[];
    /**
     * The properties to filter by
     */
    properties?: PropertyDTO[];
    /**
     * The school districts to be able to filter by
     */
    schoolDistricts?: SchoolDistrictDTO[];
};

export const AmenitiesPageSearchBlock = forwardRef(
    (
        { className, isLoading, neighborhoods = [], properties = [], schoolDistricts = [] }: AmenitiesPageSearchBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const { parameters, addQueryParameter, removeQueryParameters } = useQueryParameters();
        const { screenWidth } = useScreenSize();

        const { amenities: sortedAmenitiesKey } = useAmenities();

        const sortedAmenityNames: string[] = useMemo(() => {
            if (neighborhoods.length === 0 || sortedAmenitiesKey.length === 0) {
                return [];
            }

            const amenities: string[] = [];

            neighborhoods.forEach((neighborhood) => {
                const neighborhoodAmenities = neighborhood.amenities;

                if (!neighborhoodAmenities || neighborhoodAmenities.length === 0) {
                    return;
                }

                neighborhoodAmenities.forEach((amenity) => {
                    const { name } = amenity;
                    if (!amenities.includes(name)) {
                        amenities.push(name);
                    }
                });
            });

            const sortedAmenitiesOrder = sortedAmenitiesKey.map((amenity) => amenity.name);
            const sortedAmenities = amenities.sort((a, b) => sortedAmenitiesOrder.indexOf(a) - sortedAmenitiesOrder.indexOf(b));

            return sortedAmenities;
        }, [sortedAmenitiesKey, neighborhoods]);

        function onAmenityTabClick(selectedAmenity: string) {
            // If no currently selected amenities, just add the selected one
            if (!selectedAmenities) {
                addQueryParameter(WizardQueryParameterKeys.AMENITY, selectedAmenity);
                return;
            }

            // If current selected amenities do not include the new one, append it to the end
            if (!selectedAmenities.includes(selectedAmenity)) {
                const joinedAmenities = `${selectedAmenities},${selectedAmenity}`;
                addQueryParameter(WizardQueryParameterKeys.AMENITY, joinedAmenities);
                return;
            }

            const selectedAmenitiesArray = selectedAmenities.split(",");
            // If current selected amenity is included in current list filter it out.
            const filteredSelectedAmenitiesArray = selectedAmenitiesArray.filter((value) => value !== selectedAmenity);

            // If some are left add them to parameters
            if (filteredSelectedAmenitiesArray.length > 0) {
                const joinedAmenities = filteredSelectedAmenitiesArray.join(",");
                addQueryParameter(WizardQueryParameterKeys.AMENITY, joinedAmenities);
                return;
            }

            // If none are left remove amenity filter all together
            removeQueryParameters(WizardQueryParameterKeys.AMENITY);
        }

        function getIconTabs() {
            const iconTabs = sortedAmenityNames.map((amenityName) => {
                const iconComponent = getIconForAmenity(amenityName);
                const tabIsSelected = selectedAmenities?.includes(amenityName);
                const tabOnClick = () => onAmenityTabClick(amenityName);

                return (
                    <IconTab key={amenityName} name={amenityName} icon={iconComponent} isSelected={tabIsSelected} onClick={tabOnClick} />
                );
            });

            return iconTabs;
        }

        // Get currently selected amenities
        const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;
        const selectedAmenities = parameters[WizardQueryParameterKeys.AMENITY] as string | undefined;
        const classes = classNames(styles.root, className);

        return (
            <div ref={ref} className={classes}>
                <div className={styles.stickyHeader}>
                    {isDesktop && (
                        <HorizontalSectionHeader
                            title="Select An Amenity"
                            subtitle="Choose 1 or more of our amazing amenities to view neighborhoods that match your preferences!"
                        />
                    )}
                    <IconTabCarousel>{getIconTabs()}</IconTabCarousel>
                </div>
                <EntitySearchBlock
                    className={styles.searchBlock}
                    mobileListViewSwitchClassName={styles.mobileListViewSwitch}
                    mobileMapWrapperClassName={styles.mobileMapWrapper}
                    mobileSearchBarWrapperClassName={styles.mobileSearchBarWrapper}
                    filterNeighborhoods={true}
                    isLoading={isLoading}
                    pagination={false}
                    neighborhoods={neighborhoods}
                    properties={properties}
                    schoolDistricts={schoolDistricts}
                />
            </div>
        );
    }
);
