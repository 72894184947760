import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";

import { FAQDTO } from "@executivehomes/eh-website-api";

import { Orientation } from "../../../utilities/enums/Orientation";
import { getIconForFAQCategory } from "../../../utilities/mappers/icons/getIconForFAQCategory";
import { IconTabCarousel } from "../../carousels/icon-tab-carousel";
import { ChevronIcon } from "../../icons/chevron-icon";
import { SearchInput } from "../../inputs/search-input";
import { CardListNoResults } from "../../lists/card-list/card-list-no-results";
import { IconTab } from "../../tabs/icon-tab";

import styles from "./faq-section.module.scss";
import classNames from "classnames";

type FAQCategory = { [key: string]: FAQDTO[] };

export type FAQSectionHandle = {
    onPageScroll: () => void;
};

export type FAQSectionProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The list of faq items you want to display within this section
     */
    faqItems?: FAQDTO[] | undefined;
};

export const FAQSection = forwardRef(({ className, faqItems = [] }: FAQSectionProps, ref: React.ForwardedRef<FAQSectionHandle>) => {
    const faqSectionRefs = useRef<(HTMLElement | null)[]>([]);

    const [searchText, setSearchText] = useState<string>("");
    const [selectedTabTitle, setSelectedTabTitle] = useState<string>();
    const [openFAQs, setOpenFAQs] = useState<string[]>([]);

    const { faqCategories, faqCategoryNames } = useMemo(() => {
        const faqCategories: FAQCategory = {};

        faqItems.forEach((faq) => {
            const category = faqCategories[faq.categoryType];

            if (!category) {
                faqCategories[faq.categoryType] = [faq];
                return;
            }

            faqCategories[faq.categoryType] = [...faqCategories[faq.categoryType], faq];
        });

        const faqCategoryNames = Object.keys(faqCategories);

        const firstCategoryName = faqCategoryNames[0];
        if (firstCategoryName) {
            setSelectedTabTitle(firstCategoryName);
        }

        return { faqCategories, faqCategoryNames };
    }, [faqItems]);

    // Expose functions to the parent
    useImperativeHandle(ref, () => ({
        onPageScroll,
    }));

    //#region Event Handlers
    function onFAQClick(faq: FAQDTO) {
        const question = faq.question;

        // If the open FAQs includes the clicked question, remove it from the list (therefore closing the FAQ)
        if (openFAQs.includes(faq.question)) {
            const removedFAQList = openFAQs.filter((_question) => _question !== question);
            setOpenFAQs(removedFAQList);
            return;
        }

        const newOpenFAQs = [...openFAQs, question];
        setOpenFAQs(newOpenFAQs);
    }

    function onIconTabClick(faqCategoryName: string) {
        setSearchText("");
        // Fixes race condition with onPageContainerScroll
        setTimeout(() => setSelectedTabTitle(faqCategoryName), 0);
    }

    function onSearchInputChange(searchText: string) {
        setSearchText(searchText);

        if (searchText.trim() === "") {
            const firstFAQCategoryName = faqCategoryNames[0];
            setSelectedTabTitle(firstFAQCategoryName);
            return;
        }

        // Unselect any top bar when typing
        setSelectedTabTitle(undefined);
    }

    function onPageScroll() {
        // Add 120 pixels due to the anchor location
        const pageContainerTop = window.scrollY + 120;

        let foundIndex = 0;
        for (let index = faqSectionRefs.current.length; index > 0; index--) {
            const section = faqSectionRefs.current[index];

            if (!section) {
                continue;
            }

            if (pageContainerTop >= section.offsetTop) {
                foundIndex = index;
                break;
            }
        }

        setSelectedTabTitle(faqCategoryNames[foundIndex]);
    }
    //#endregion

    //#region Render Functions
    function getIconTabs() {
        const tabs: JSX.Element[] = [];

        faqCategoryNames.forEach((faqCategoryName) => {
            const isSelected = selectedTabTitle === faqCategoryName;
            const href = `#${faqCategoryName}`;

            tabs.push(
                <IconTab
                    key={faqCategoryName}
                    icon={getIconForFAQCategory(faqCategoryName)}
                    name={faqCategoryName}
                    isSelected={isSelected}
                    href={href}
                    onClick={() => onIconTabClick(faqCategoryName)}
                />
            );
        });

        return tabs;
    }

    function getFAQSection() {
        if (searchText.trim() !== "") {
            const filteredFAQBySearchText = faqItems.filter((faq) => {
                const lowerCaseSearchText = searchText.toLowerCase();
                const question = faq.question.toLowerCase();
                const answer = faq.answerMarkup.toLowerCase();

                return question.includes(lowerCaseSearchText) || answer.includes(lowerCaseSearchText);
            });

            if (filteredFAQBySearchText.length === 0) {
                return <CardListNoResults />;
            }

            const faqHtmls = filteredFAQBySearchText.map(getFAQHtml);

            return (
                <section className={styles.faqSection}>
                    <h2 className={styles.faqCategoryTitle}>Search Results</h2>
                    <div className={styles.accordion}>{faqHtmls}</div>
                </section>
            );
        }

        const faqCategoryDisplays = faqCategoryNames.map((faqCategoryName, index) => {
            const faqCategory = faqCategories[faqCategoryName];
            const faqHtmls = faqCategory.map(getFAQHtml);

            return (
                <section ref={(ref) => (faqSectionRefs.current[index] = ref)} key={faqCategoryName} className={styles.faqSection}>
                    {/* This anchor is here to have a place to snap to  */}
                    <a id={faqCategoryName} className={styles.sectionAnchor} />
                    <h2 className={styles.faqCategoryTitle}>{faqCategoryName}</h2>
                    <div className={styles.accordion}>{faqHtmls}</div>
                </section>
            );
        });

        return faqCategoryDisplays;
    }

    function getFAQHtml(faq: FAQDTO, index: number) {
        const question = faq.question;
        const isOpen = openFAQs.includes(question);
        const arrowDirection = isOpen ? Orientation.UP : Orientation.DOWN;

        const singleFAQClasses = classNames(styles.accordionItem, isOpen && styles.open);

        return (
            <div key={index} className={singleFAQClasses}>
                <h3 className={styles.question} onClick={() => onFAQClick(faq)}>
                    {question}
                    <ChevronIcon className={styles.chevron} arrowDirection={arrowDirection} />
                </h3>
                <div className={styles.answer} dangerouslySetInnerHTML={{ __html: faq.answerMarkup }} />
            </div>
        );
    }
    //#endregion

    const classes = classNames(styles.root, className);

    return (
        <section className={classes}>
            <SearchInput
                className={styles.searchInput}
                placeHolder="Search our questions"
                value={searchText}
                onInputChange={onSearchInputChange}
            />
            <IconTabCarousel className={styles.stickyTop}>{getIconTabs()}</IconTabCarousel>
            {getFAQSection()}
        </section>
    );
});
