import { Orientation } from "../../../utilities/enums/Orientation";
import { ArrowIcon } from "../arrow-icon";
import { FloorPlanIcon } from "../floor-plan-icon";

import styles from "./square-foot-sort-icon.module.scss";
import classNames from "classnames";

export type SquareFootSortIconProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the arrow is pointed up or down indicating an ascending or descending sort order
     */
    isAscending?: boolean;
};

export function SquareFootSortIcon({ className, isAscending = false }: SquareFootSortIconProps) {
    const arrowDirection = isAscending ? Orientation.UP : Orientation.DOWN;

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <FloorPlanIcon className={styles.floorPlanIcon} showHashing={false} />
            <ArrowIcon className={styles.arrowIcon} arrowDirection={arrowDirection} />
        </div>
    );
}
