import { useEffect, useRef, useState } from "react";

import { createPortal } from "react-dom";
import { Document, Page as PDFPage, pdfjs } from "react-pdf";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { Orientation } from "../../../utilities/enums/Orientation";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { ChevronIcon } from "../../icons/chevron-icon";
import { MinusIcon } from "../../icons/minus-icon";
import { PlusIcon } from "../../icons/plus-icon";
import { XIcon } from "../../icons/x-icon";

import styles from "./pdf-viewer.module.scss";
import classNames from "classnames";

// Initialize the PDF worker
// https://github.com/wojtekmaj/react-pdf/issues/852
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Page number of PDFs start at 1 and not 0
const INITIAL_PAGE_NUMBER = 1;
const INITIAL_ZOOM_PERCENT = 100;
const MINIMUM_ZOOM_LEVEL = 5;
const MAXIMUM_ZOOM_LEVEL = 1000;

const SCROLL_TOLERANCE_BEFORE_PAGING = 5;
const SCROLL_TOLERANCE_DECAY_TIME_IN_MILLISECONDS = 50;

export type PDFViewerProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Controls whether this PDFViewer is open or not from the parent
     */
    isOpen?: boolean;
    /**
     * The url to the PDF to display
     */
    url?: string;
    /**
     * What to do on the viewer closing
     * Typically this is where you pass the state change for the
     * parent controlling if this is visible or not
     */
    onPdfViewerClose?: () => void;
};

export function PDFViewer({ className, isOpen = false, url, onPdfViewerClose }: PDFViewerProps) {
    //#region useRefs
    const overlayDivRef = useRef<HTMLDivElement>(null);
    const pageNumberInputRef = useRef<HTMLInputElement>(null);
    const zoomInputRef = useRef<HTMLInputElement>(null);
    const scrollCount = useRef<number>(0);
    //#endregion

    //#region useStates
    const [pageIsLoaded, setPageLoaded] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState<number>(0);
    const [pageNumberText, setPageNumberText] = useState<string>(`${INITIAL_PAGE_NUMBER}`);
    const [pageNumber, setPageNumber] = useState<number>(INITIAL_PAGE_NUMBER);
    const [zoomText, setZoomText] = useState<string>(`${INITIAL_ZOOM_PERCENT}%`);
    const [zoom, setZoom] = useState<number>(INITIAL_ZOOM_PERCENT);
    const [renderedPageNumber, setRenderedPageNumber] = useState<number>();
    const [renderedZoom, setRenderedZoom] = useState<number>();
    //#endregion

    const { screenWidth, screenHeight } = useScreenSize();

    //#region Event Handlers
    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setPageCount(numPages);
    }

    function onPageNumberInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPageNumberText(event.target.value);
    }

    function onPageNumberInputBlur() {
        const unparsedText = pageNumberText;
        const parsedPageNumber = parseInt(unparsedText);

        if (isNaN(parsedPageNumber)) {
            setPageNumberText(`${pageNumber}`);
            return;
        }

        setPage(parsedPageNumber);
    }

    function onPageNumberInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key !== "Enter" && event.key !== "Tab") {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (!pageNumberInputRef.current) {
            return;
        }

        pageNumberInputRef.current.blur();
    }

    function onPreviousPress(event?: React.MouseEvent<HTMLButtonElement>) {
        if (event) {
            cancelEvent(event);
        }

        let previousPageNumber = pageNumber - 1;

        if (previousPageNumber <= 0) {
            previousPageNumber = pageCount;
        }

        setPage(previousPageNumber);
    }

    function onNextPress(event?: React.MouseEvent<HTMLButtonElement>) {
        if (event) {
            cancelEvent(event);
        }

        let nextPageNumber = pageNumber + 1;

        if (nextPageNumber > pageCount) {
            nextPageNumber = 1;
        }

        setPage(nextPageNumber);
    }

    function setPage(_pageNumber: number) {
        let correctedPageNumber = _pageNumber;

        if (correctedPageNumber < 1) {
            correctedPageNumber = 1;
        } else if (correctedPageNumber > pageCount) {
            correctedPageNumber = pageCount;
        }

        setPageNumberText(`${correctedPageNumber}`);

        if (pageNumber === correctedPageNumber) {
            return;
        }

        setPageLoaded(false);
        setPageNumber(correctedPageNumber);
    }

    function onZoomInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        setZoomText(event.target.value);
    }

    function onZoomInputBlur() {
        const unparsedText = zoomText.replace("%", "");
        const parsedZoom = parseInt(unparsedText);

        if (isNaN(parsedZoom)) {
            setZoomText(`${zoom}%`);
            return;
        }

        onZoom(parsedZoom);
    }

    function onZoomInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key !== "Enter" && event.key !== "Tab") {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (!zoomInputRef.current) {
            return;
        }

        zoomInputRef.current.blur();
    }

    function onZoomOut() {
        onZoom(zoom * 0.9);
    }

    function onZoomIn() {
        onZoom(zoom * 1.1);
    }

    function onZoom(newZoomAmount: number) {
        if (newZoomAmount < MINIMUM_ZOOM_LEVEL) {
            newZoomAmount = MINIMUM_ZOOM_LEVEL;
        } else if (newZoomAmount > MAXIMUM_ZOOM_LEVEL) {
            newZoomAmount = MAXIMUM_ZOOM_LEVEL;
        }

        setZoomText(`${newZoomAmount}%`);

        if (newZoomAmount === zoom) {
            return;
        }

        setPageLoaded(false);
        setZoom(newZoomAmount);
        setZoomText(`${Math.round(newZoomAmount)}%`);
    }

    function closePdfViewer() {
        setPageLoaded(false);
        setPageNumber(INITIAL_PAGE_NUMBER);
        setPageNumberText(`${INITIAL_PAGE_NUMBER}`);
        setPageCount(0);
        setZoom(INITIAL_ZOOM_PERCENT);
        setRenderedPageNumber(undefined);
        setRenderedZoom(undefined);

        if (onPdfViewerClose) {
            onPdfViewerClose();
        }
    }

    function onPDFPageLoad() {
        setPageLoaded(true);
        setRenderedPageNumber(pageNumber);
        setRenderedZoom(zoom);
        scrollCount.current = 0;
    }

    function cancelEvent(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        event.preventDefault();
    }

    /**
     * This handles the hard scrolling that Chandler wanted to match Adobe
     */
    function onScroll(event: WheelEvent) {
        if (!overlayDivRef.current || !pageIsLoaded) {
            return;
        }

        const { scrollTop, scrollHeight, clientHeight } = overlayDivRef.current;

        // If at the top while scrolling up
        const isScrollingUpAtTop = scrollTop <= 0 && event.deltaY < 0;
        if (isScrollingUpAtTop) {
            scrollCount.current -= 1;

            if (scrollCount.current < -SCROLL_TOLERANCE_BEFORE_PAGING) {
                onPreviousPress();
                return;
            }

            setTimeout(() => {
                if (scrollCount.current < 0) {
                    scrollCount.current += 1;
                }
            }, SCROLL_TOLERANCE_DECAY_TIME_IN_MILLISECONDS);
            return;
        }

        // If at the bottom while scrolling down
        const isScrollingDownAtBottom = scrollTop + clientHeight >= scrollHeight - 1 && event.deltaY > 0;
        if (isScrollingDownAtBottom) {
            scrollCount.current += 1;

            if (scrollCount.current > SCROLL_TOLERANCE_BEFORE_PAGING) {
                onNextPress();
                return;
            }

            setTimeout(() => {
                if (scrollCount.current > 0) {
                    scrollCount.current -= 1;
                }
            }, SCROLL_TOLERANCE_DECAY_TIME_IN_MILLISECONDS);
        }
    }
    //#endregion

    //#region useEffects
    // When we open the overlay, hide the scrollbar for the page
    useEffect(() => {
        document.body.style.overflow = isOpen ? "hidden" : "visible";
    }, [isOpen]);

    // When we have a PDF that is loaded, scroll to the middle of it
    useEffect(() => {
        const overlayDiv = overlayDivRef.current;

        if (!overlayDiv || !isOpen || !pageIsLoaded) {
            return;
        }

        // Scroll to the middle of the screen vertically
        const verticalScrollAmount = (overlayDiv.scrollHeight - screenHeight) / 2;
        if (verticalScrollAmount > 0) {
            overlayDiv.scrollTop = verticalScrollAmount;
        }

        // Scroll to the middle of the screen horizontally
        const horizontalScrollAmount = (overlayDiv.scrollWidth - screenWidth) / 2;
        if (horizontalScrollAmount > 0) {
            overlayDiv.scrollLeft = horizontalScrollAmount;
        }
    }, [pageIsLoaded]);

    useEffect(() => {
        const overlay = overlayDivRef.current;

        if (!overlay) {
            return;
        }

        // Set up a listener to listen for scrolls that COULD be hard scrolls
        overlay.addEventListener("wheel", onScroll);

        return () => {
            overlay.removeEventListener("wheel", onScroll);
        };
    }, [isOpen, pageIsLoaded]);
    //#endregion

    if (!isOpen) {
        return null;
    }

    function getControls() {
        const totalPageCount = pageCount || "-";

        const leftPageChangeButton = classNames(styles.pageChangeButton, styles.left);
        const rightPageChangeButton = classNames(styles.pageChangeButton, styles.right);

        return (
            <>
                <div className={styles.pdfControls} onClick={cancelEvent}>
                    <div className={styles.pageInfo}>
                        <div>Page</div>
                        <input
                            ref={pageNumberInputRef}
                            className={styles.pageInput}
                            type="text"
                            value={pageNumberText}
                            onChange={onPageNumberInputChange}
                            onBlur={onPageNumberInputBlur}
                            onKeyDown={onPageNumberInputKeyDown}
                        />
                        <div className={styles.grayText}>/</div>
                        <div className={styles.grayText}>{totalPageCount}</div>
                    </div>
                    <div className={styles.pageZoom}>
                        <BaseButton className={styles.zoomButton} buttonStyle={ButtonStyle.NONE} onClick={onZoomOut}>
                            <MinusIcon width={14} height={3} />
                        </BaseButton>
                        <input
                            ref={zoomInputRef}
                            className={styles.zoomInput}
                            type="text"
                            value={zoomText}
                            onChange={onZoomInputChange}
                            onBlur={onZoomInputBlur}
                            onKeyDown={onZoomInputKeyDown}
                        />
                        <BaseButton className={styles.zoomButton} buttonStyle={ButtonStyle.NONE} onClick={onZoomIn}>
                            <PlusIcon width={13} height={13} />
                        </BaseButton>
                    </div>
                </div>
                <BaseButton className={leftPageChangeButton} buttonStyle={ButtonStyle.NONE} onClick={onPreviousPress}>
                    <ChevronIcon arrowDirection={Orientation.LEFT} />
                </BaseButton>
                <BaseButton className={rightPageChangeButton} buttonStyle={ButtonStyle.NONE} onClick={onNextPress}>
                    <ChevronIcon />
                </BaseButton>
                <BaseButton className={styles.closeButton} buttonStyle={ButtonStyle.NONE} onClick={closePdfViewer}>
                    <XIcon className={styles.xIcon} />
                </BaseButton>
            </>
        );
    }

    function getPDFDocumentAndPages() {
        const pdfPageClassNames = !pageIsLoaded ? styles.hiddenPdfPage : "";

        return (
            <>
                <Document className={styles.pdfDocument} file={url} onLoadSuccess={onDocumentLoadSuccess} onClick={cancelEvent}>
                    <PDFPage
                        key={`${pageNumber}:${zoom}`}
                        className={pdfPageClassNames}
                        pageNumber={pageNumber}
                        scale={zoom / 100}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        onRenderSuccess={onPDFPageLoad}
                    />
                    {!pageIsLoaded && renderedPageNumber && renderedZoom ? (
                        <PDFPage
                            key={`${renderedPageNumber}:${renderedZoom}`}
                            className={styles.pdfPage}
                            pageNumber={renderedPageNumber}
                            scale={renderedZoom / 100}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                        />
                    ) : null}
                </Document>
                {!pageIsLoaded && <div className={styles.loadingIndicator}>Loading...</div>}{" "}
            </>
        );
    }

    const classes = classNames(styles.root, className);

    return createPortal(
        <div ref={overlayDivRef} className={classes} onClick={closePdfViewer}>
            {getPDFDocumentAndPages()}
            {getControls()}
        </div>,
        document.body
    );
}
