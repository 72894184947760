import { useEffect, useRef, useState } from "react";

import { Orientation } from "../../../utilities/enums/Orientation";
import { ChevronIcon } from "../../icons/chevron-icon";
import { InvalidFieldMessage } from "../../messages/invalid-field-message";
import { InputStyle } from "../inputStyle";

import styles from "./input-select.module.scss";
import classNames from "classnames";

export type InputSelectProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Style you want to use for the input
     */
    inputStyle?: InputStyle;
    /**
     * Input title
     */
    title?: string;
    /**
     * Options for the selector
     */
    options?: string[];
    /**
     * name of the select element
     */
    name?: string;
    /**
     * Is the field required
     */
    valid?: boolean;
    /**
     * The message to display if invalid
     */
    invalidMessage?: string;
    /**
     * The function to call when the value changes
     */
    onChange?: (key: string, value: string) => void;
};

export function InputSelect({
    className,
    inputStyle = InputStyle.GRAY_TEXT,
    title = "Input Select",
    options,
    name = "",
    valid = true,
    invalidMessage,
    onChange,
}: InputSelectProps) {
    const parentRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>(title);

    // Add blur event on the whole object
    useEffect(() => {
        document.addEventListener("mouseup", onWindowMouseUp);

        return () => {
            document.removeEventListener("mouseup", onWindowMouseUp);
        };
    }, []);

    //#region Event Handlers
    function onWindowMouseUp(e: MouseEvent) {
        if (parentRef.current && parentRef.current.contains(e.target as Node)) {
            return;
        }

        setOpen(false);
    }

    function onOptionButtonClick(option: string) {
        setOpen(false);
        setSelectedValue(option);
        if (onChange) {
            onChange(name, option);
        }
    }
    //#endregion

    const isDark = inputStyle === InputStyle.DARK_TEXT;
    const arrowDirection = isOpen ? Orientation.UP : Orientation.DOWN;

    const classes = classNames(styles.root, isDark && styles.darkText, className);
    const selectedValueButtonClasses = classNames(styles.displayButton, selectedValue !== title && styles.selected);

    return (
        <div ref={parentRef} className={classes}>
            <button className={selectedValueButtonClasses} onClick={() => setOpen(!isOpen)} type="button">
                {selectedValue}
                <ChevronIcon className={styles.chevronIcon} arrowDirection={arrowDirection} />
            </button>
            {isOpen && (
                <div className={styles.dropdown}>
                    {options?.map((option, index) => {
                        const optionButtonClasses = classNames(styles.optionButton, selectedValue === option && styles.selectedOption);

                        return (
                            <button key={index} className={optionButtonClasses} onClick={() => onOptionButtonClick(option)} type="button">
                                {option}
                            </button>
                        );
                    })}
                </div>
            )}
            {!valid && <InvalidFieldMessage className={styles.invalidMessage} text={invalidMessage} />}
        </div>
    );
}
