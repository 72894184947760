import { ReactElement, useMemo } from "react";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { BaseCarousel } from "../base-carousel";
import { CarouselArrowStyle } from "../base-carousel/base-carousel";

import styles from "./icon-tab-carousel.module.scss";
import classNames from "classnames";

export type IconTabCarouselProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Children to pass down to the carousel
     */
    children?: ReactElement[];
};

export function IconTabCarousel({ className, children = [] }: IconTabCarouselProps) {
    const { screenWidth } = useScreenSize();

    const slidesPerView = useMemo(() => {
        const iconTabWidth = screenWidth < HorizontalBreakpoint.SMALL ? 100 : 180;

        // -48 for buttons on each side (24 x 2)
        const slidesPerView = Math.floor((screenWidth - 48) / iconTabWidth);

        // If there is not enough children to fill slide per view, set slides per view to be the children length instead
        if (slidesPerView > children.length) {
            return children.length;
        }

        return slidesPerView;
    }, [screenWidth, children.length]);

    const classes = classNames(styles.root, className);

    return (
        <BaseCarousel
            className={classes}
            arrows={true}
            infinite={false}
            slideGap={16}
            slidesPerView={slidesPerView}
            slidesPerClick={slidesPerView}
            arrowsStyle={CarouselArrowStyle.BORDERED_EXTERIOR_ARROWS}
        >
            {children}
        </BaseCarousel>
    );
}
