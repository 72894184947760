import { ReactElement, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { useLocation } from "react-router-dom";

import { ConstructionStatus, FloorPlanDTO, NeighborhoodDTO, PropertyDTO } from "@executivehomes/eh-website-api";
import { SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { usePropertyListController } from "../../../hooks/usePropertyListController";
import { WizardQueryParameterKeys, WizardQueryParameterValues, useQueryParameters } from "../../../hooks/useQueryParameters";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { CardDisplayState } from "../../../utilities/enums/CardDisplayState";
import { SearchTextFilterByType } from "../../../utilities/enums/SearchTextFilterByType";
import { FloorPlanFilter, filterFloorPlans } from "../../../utilities/filters/floor-plan/filterFloorPlans";
import { PropertyFilter, getPropertiesInAndOutOfMapBounds } from "../../../utilities/filters/property/filterProperties";
import { isPropertyFilterPanelActive } from "../../../utilities/filters/property/isPropertyFilterPanelActive";
import { isPerimeterInBounds } from "../../../utilities/locations/isPerimeterInBounds";
import { isOnAmenitiesPath } from "../../../utilities/routing/utils/isOnAmenitiesPath";
import { isOnBrowsePath } from "../../../utilities/routing/utils/isOnBrowsePath";
import { isOnFloorPlansPath } from "../../../utilities/routing/utils/isOnFloorPlansPath";
import { isOnNeighborhoodPath } from "../../../utilities/routing/utils/isOnNeighborhoodPath";
import { isOnNeighborhoodsPath } from "../../../utilities/routing/utils/isOnNeighborhoodsPath";
import { sortFloorPlans } from "../../../utilities/sorts/floor-plan/sortFloorPlans";
import { Bounds } from "../../../utilities/types/Bounds";
import { FloorPlanCard } from "../../cards/floor-plan-card";
import { NeighborhoodCard } from "../../cards/neighborhood-card";
import { PlaceholderCard } from "../../cards/placeholder-card";
import { PropertyCard } from "../../cards/property-card";
import { BaseCarousel } from "../../carousels/base-carousel";
import { ListIcon } from "../../icons/list-icon";
import { LocationPinIcon } from "../../icons/location-pin-icon";
import { MapIcon } from "../../icons/map-icon";
import { MultiStateSwitch, MultiStateSwitchStyle } from "../../inputs/multi-state-switch";
import { SearchInputWithFilter } from "../../inputs/search-input-with-filter";
import { CardList, CardListHandle } from "../../lists/card-list/card-list";
import { CardListNoResults } from "../../lists/card-list/card-list-no-results";
import { PropertyCardList } from "../../lists/property-card-list";
import { GoogleMaps, GoogleMapsHandle, GoogleMapsProps } from "../../maps/google-maps/google-maps";
import { CardListDivider } from "../../misc/card-list-divider";
import { LoadingSpinner } from "../../misc/loading-spinner";

import styles from "./entity-search-block.module.scss";
import classNames from "classnames";

const MULTI_STATE_SWITCH_OPTIONS: string[] = ["Properties", "Floor Plans"];

export type MapProps = Partial<Pick<GoogleMapsProps, "minZoom" | "startingCenter" | "perimeter">>;

export type EntitySearchBlockHandle = {
    propertyFilter: PropertyFilter;
    changeConstructionStatusFilter: (constructionStatuses: ConstructionStatus[]) => void;
    highlightOrHideSchoolDistrictPolygon: (schoolDistrict: SchoolDistrictDTO, hidden: boolean) => void;
    unsetSelectedProperty: () => void;
};

export type EntitySearchBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Additional classnames to apply to mobile list view switch
     */
    mobileListViewSwitchClassName?: string;
    /**
     * Additional classnames to apply to mobile map wrapper
     */
    mobileMapWrapperClassName?: string;
    /**
     * Additional classnames to apply to mobile search bar wrapper
     */
    mobileSearchBarWrapperClassName?: string;
    /**
     * Whether you want to filter the neighborhoods displayed on the map
     * to only ones containing properties meeting the filter
     */
    filterNeighborhoods?: boolean;
    /**
     * The floor plans to show in the card panel
     */
    floorPlans?: FloorPlanDTO[];
    /**
     * Whether the floor plan cards are vertical or not;
     */
    floorPlanCardsAreVertical?: boolean;
    /**
     * The extra filter wanted to be applied to the floor plans
     */
    floorPlanFilter?: FloorPlanFilter;
    /**
     * Whether the list is currently loading from the api
     */
    isLoading?: boolean;
    /**
     * The props to initialize the map with
     */
    mapProps?: MapProps;
    /**
     * The neighborhoods to render on the map
     */
    neighborhoods?: NeighborhoodDTO[];
    /**
     * Whether to paginate the content within the list or not
     * @default true
     */
    pagination?: boolean;
    /**
     * The properties to show on the card panel and map
     */
    properties?: PropertyDTO[];
    /**
     * The school districts to show on the card panel and map
     */
    schoolDistricts?: SchoolDistrictDTO[];
    /**
     * Whether the card list panel should show floor plan cards or not
     */
    showFloorPlanCards?: boolean;
    /**
     * Custom override to the default filtering behavior on a floor plan card click
     */
    onFloorPlanCardClick?: (floorPlan: FloorPlanDTO) => void;
};

export const EntitySearchBlock = forwardRef(
    (
        {
            className,
            mobileListViewSwitchClassName,
            mobileMapWrapperClassName,
            mobileSearchBarWrapperClassName,
            filterNeighborhoods = false,
            floorPlans = [],
            floorPlanCardsAreVertical,
            floorPlanFilter,
            isLoading,
            mapProps,
            neighborhoods = [],
            pagination,
            properties,
            schoolDistricts = [],
            showFloorPlanCards = false,
            onFloorPlanCardClick,
        }: EntitySearchBlockProps,
        ref: React.ForwardedRef<EntitySearchBlockHandle>
    ) => {
        const rootRef = useRef<HTMLDivElement>(null);
        const leftContainerRef = useRef<HTMLDivElement>(null);
        const cardListRef = useRef<CardListHandle>(null);
        const googleMapsRef = useRef<GoogleMapsHandle>(null);
        const shouldGetPerimeterOnNextFilter = useRef<boolean>(true);

        const [mapPerimeter, setMapPerimeter] = useState<number[][]>();
        const [mobileCarouselIndex, setMobileCarouselIndex] = useState<number>(-1);
        const [mapBoundsFilter, setMapBoundsFilter] = useState<Bounds>();
        const [propertyWithSelectedBorder, setPropertyWithSelectedBorder] = useState<PropertyDTO>();

        const [onListViewMobile, setOnListViewMobile] = useState<boolean>(true);

        const propertyListController = usePropertyListController(properties);
        const {
            sortedFilteredProperties,
            propertyFilter,
            propertySort,
            displayPropertyFloorPlan,
            changeConstructionStatusFilter,
            changeFloorPlanFilter,
        } = propertyListController;

        const { screenWidth } = useScreenSize();
        const location = useLocation();
        const { parameters, addQueryParameter, removeQueryParameters } = useQueryParameters();

        const filterByType = useMemo(() => {
            const filterByFloorPlan =
                parameters[WizardQueryParameterKeys.BY] === WizardQueryParameterValues.FLOOR_PLANS || isOnFloorPlansPath(location.pathname);
            if (filterByFloorPlan) {
                return SearchTextFilterByType.FLOOR_PLAN;
            }

            const filterByNeighborhood = isOnNeighborhoodsPath(location.pathname) || isOnAmenitiesPath(location.pathname);
            if (filterByNeighborhood) {
                return SearchTextFilterByType.NEIGHBORHOOD;
            }

            if (isOnNeighborhoodPath(location.pathname)) {
                return SearchTextFilterByType.ADDRESS_OR_FLOOR_PLAN;
            }

            return SearchTextFilterByType.ALL;
        }, [location.pathname, parameters[WizardQueryParameterKeys.BY]]);

        const isMobile = useMemo(() => screenWidth < HorizontalBreakpoint.MEDIUM, [screenWidth]);

        const { inBounds: inBoundsProperties, outOfBounds: outOfBoundsProperties } = useMemo(
            () => getPropertiesInAndOutOfMapBounds(sortedFilteredProperties, mapBoundsFilter),
            [mapBoundsFilter, sortedFilteredProperties]
        );

        const filteredSortedFloorPlans = useMemo(() => {
            const filteredFloorPlans = filterFloorPlans(floorPlans, propertyFilter, floorPlanFilter);

            return sortFloorPlans(filteredFloorPlans, propertySort);
        }, [floorPlans, floorPlanFilter, propertyFilter, propertySort]);

        const sortedFilteredNeighborhoods = useMemo(() => {
            if (!filterNeighborhoods) {
                return neighborhoods;
            }

            const neighborhoodSet = new Set(
                sortedFilteredProperties.map((property) => property.neighborhood?.name).filter((name) => name) as string[]
            );

            // If filter type is neighborhood, filter all neighborhoods by name contains search text
            const filterNeighborhoodsByName = filterByType === SearchTextFilterByType.NEIGHBORHOOD;

            const filteredNeighborhoods = neighborhoods.filter((neighborhood) => {
                const isInSet = neighborhoodSet.has(neighborhood.name);

                // If not filtering by name or no text to filter name by just return if it is in the set
                if (!filterNeighborhoodsByName || !propertyFilter.searchText) {
                    return isInSet;
                }

                const lowerCaseNeighborhoodName = neighborhood.name.toLowerCase();

                return lowerCaseNeighborhoodName.includes(propertyFilter.searchText);
            });

            const sortedFilteredNeighborhoods = filteredNeighborhoods.sort((a, b) => a.name.localeCompare(b.name));

            return sortedFilteredNeighborhoods;
        }, [sortedFilteredProperties, neighborhoods, propertyFilter.searchText]);

        const { inBoundNeighborhoods, outOfBoundNeighborhoods } = useMemo(() => {
            const inBoundNeighborhoods: NeighborhoodDTO[] = [];
            const outOfBoundNeighborhoods: NeighborhoodDTO[] = [];

            sortedFilteredNeighborhoods.forEach((neighborhood) => {
                const perimeter = neighborhood?.location?.perimeter;

                if (perimeter && mapBoundsFilter && isPerimeterInBounds(perimeter, mapBoundsFilter)) {
                    inBoundNeighborhoods.push(neighborhood);
                    return;
                }

                outOfBoundNeighborhoods.push(neighborhood);
            });

            return { inBoundNeighborhoods, outOfBoundNeighborhoods };
        }, [mapBoundsFilter, sortedFilteredNeighborhoods]);

        // Expose functions to the parent
        useImperativeHandle(ref, () => ({
            propertyFilter,
            changeConstructionStatusFilter,
            highlightOrHideSchoolDistrictPolygon,
            unsetSelectedProperty,
        }));

        //#region useEffects
        useEffect(() => {
            // When search text changes, set true to get new perimeter of filtered neighborhoods
            shouldGetPerimeterOnNextFilter.current = true;
        }, [parameters[WizardQueryParameterKeys.SEARCH_TEXT], neighborhoods]);

        useEffect(() => {
            // Only get new perimeter if prompted for it and we have sortedFilteredNeighborhoods
            if (shouldGetPerimeterOnNextFilter.current && sortedFilteredNeighborhoods && sortedFilteredNeighborhoods.length > 0) {
                shouldGetPerimeterOnNextFilter.current = false;
                setMapPerimeter(getMapStartingPerimeter());
            }
        }, [sortedFilteredNeighborhoods]);

        useEffect(() => {
            if (!isMobile) {
                unsetSelectedProperty();
            }
        }, [isMobile]);

        useEffect(() => {
            if (parameters[WizardQueryParameterKeys.MOBILE_DISPLAY_MAP]) {
                setOnListViewMobile(false);
                return;
            }

            setOnListViewMobile(true);
        }, [parameters[WizardQueryParameterKeys.MOBILE_DISPLAY_MAP]]);

        useEffect(() => {
            // If on mobile don't add click handler
            if (isMobile) {
                return;
            }

            leftContainerRef.current?.addEventListener("click", leftContainerClickHandler);

            return () => {
                leftContainerRef.current?.removeEventListener("click", leftContainerClickHandler);
            };
        }, [propertyWithSelectedBorder, isMobile]);
        //#endregion

        //#region Event Handlers
        //#region onClicks
        function onFloorPlanSwitchToggle(selectedIndex: number) {
            // Properties Tab
            if (selectedIndex === 0) {
                removeQueryParameters(WizardQueryParameterKeys.BY);
                return;
            }

            // Floor Plans Tab
            addQueryParameter(WizardQueryParameterKeys.BY, WizardQueryParameterValues.FLOOR_PLANS);
        }

        function getFloorPlanSwitchInitialSelectedPosition(): number {
            if (parameters[WizardQueryParameterKeys.BY] === WizardQueryParameterValues.FLOOR_PLANS) {
                return 1;
            }

            return 0;
        }

        function doActionIfSelectedCardNotClicked(cardId: string, target: HTMLElement, action: () => void) {
            // Get currently selected property card
            const selectedCard = document.getElementById(cardId);

            // If card is currently selected and isn't where the user clicked unselect the card.
            if (selectedCard && !selectedCard.contains(target)) {
                action();
            }
        }

        function leftContainerClickHandler(event: MouseEvent) {
            const target = event.target as HTMLElement;

            if (propertyWithSelectedBorder) {
                doActionIfSelectedCardNotClicked(propertyWithSelectedBorder.streetAddress, target, unsetSelectedProperty);
            }
        }

        function onFloorPlanCardClickHandler(floorPlan: FloorPlanDTO) {
            // If we have custom behavior, use it
            if (onFloorPlanCardClick) {
                onFloorPlanCardClick(floorPlan);
                return;
            }

            changeFloorPlanFilter(floorPlan.name);
        }

        // Use useCallback to memoize the function
        const onPropertyClickMapHandler = useCallback(
            (property: PropertyDTO | undefined) => {
                // This needs to run to tell the card lists to unselect any cards if
                // we have unselected the previously selected property
                setPropertyWithSelectedBorder(property);

                // If we do not have a property, then we can not scroll to the card in the list
                if (!property) {
                    return;
                }

                // If a property was clicked make sure you switch to property list
                removeQueryParameters(WizardQueryParameterKeys.BY);

                if (isMobile) {
                    const indexInCarousel = inBoundsProperties.findIndex((_property) => _property.streetAddress === property.streetAddress);

                    if (indexInCarousel < 0) {
                        return;
                    }

                    setMobileCarouselIndex(indexInCarousel);
                    return;
                }

                cardListRef.current?.scrollToCardByIdInList(property.streetAddress);
            },
            [isMobile, inBoundsProperties, removeQueryParameters, setPropertyWithSelectedBorder]
        );

        function onRecenterClickHandler() {
            if (googleMapsRef.current) {
                googleMapsRef.current.onRecenterClickHandler();
            }
        }
        //#endregion

        //#region onHovers
        function onPropertyCardHoverAndLeave(property: PropertyDTO, hovered: boolean) {
            googleMapsRef.current?.onPropertyHoverOrLeaveOnMap(property, hovered);
        }

        function onFloorPlanCardHoverAndLeave(floorPlan: FloorPlanDTO, hovered: boolean) {
            sortedFilteredProperties.forEach((property) => {
                // Highlight property if its selected floor plan is the same as the one being hovered
                if (property.floorPlan?.name === floorPlan.name) {
                    googleMapsRef.current?.onPropertyHoverOrLeaveOnMap(property, hovered);
                    return;
                }

                // Highlight lots if its available floor plans contains the one being hovered
                if (
                    property.constructionStatus === ConstructionStatus.AVAILABLE_LOT &&
                    property.availableFloorPlans?.some((availableFloorPlan) => availableFloorPlan.name === floorPlan.name)
                ) {
                    googleMapsRef.current?.onPropertyHoverOrLeaveOnMap(property, hovered);
                }
            });
        }

        function onNeighborhoodCardHoverAndLeave(neighborhood: NeighborhoodDTO, hovered: boolean) {
            if (googleMapsRef.current) {
                googleMapsRef.current.onNeighborhoodHoverOrLeaveOnMap(neighborhood, hovered);
            }
        }

        function highlightOrHideSchoolDistrictPolygon(schoolDistrict: SchoolDistrictDTO, hidden: boolean) {
            if (googleMapsRef.current) {
                googleMapsRef.current.highlightOrHideSchoolDistrictPolygon(schoolDistrict, hidden);
            }
        }
        //#endregion
        //#endregion

        //#region Utility Functions
        function unsetSelectedProperty() {
            if (googleMapsRef.current) {
                googleMapsRef.current.setSelectedPropertyOnMap(undefined);
            }
        }

        //#region Mobile Carousel Before Changes
        function mobileMapViewNeighborhoodCarouselBeforeChange(previousIndex: number, newIndex: number) {
            if (newIndex > inBoundNeighborhoods.length) {
                return;
            }

            // Unhover previous index
            onNeighborhoodCardHoverAndLeave(inBoundNeighborhoods[previousIndex], false);
            // Hover new index
            onNeighborhoodCardHoverAndLeave(inBoundNeighborhoods[newIndex], true);
        }

        function mobileMapViewPropertyCarouselBeforeChange(newIndex: number) {
            if (newIndex > inBoundsProperties.length) {
                return;
            }

            setPropertyWithSelectedBorder(inBoundsProperties[newIndex]);
            if (googleMapsRef.current) {
                googleMapsRef.current.setSelectedPropertyOnMap(inBoundsProperties[newIndex]);
            }
        }

        function mobileMapViewCarouselBeforeChange(previousIndex: number, newIndex: number) {
            setMobileCarouselIndex(newIndex);

            if (showNeighborhoodCards) {
                mobileMapViewNeighborhoodCarouselBeforeChange(previousIndex, newIndex);
                return;
            }

            mobileMapViewPropertyCarouselBeforeChange(newIndex);
        }

        function onMobileListViewSwitchChange() {
            addQueryParameter(WizardQueryParameterKeys.MOBILE_DISPLAY_MAP, WizardQueryParameterValues.TRUE);

            if (!rootRef.current || window.scrollY < rootRef.current.offsetTop) {
                return;
            }

            // If the window is scrolled down in list view, scroll back up to top of this block when going to map view
            // - 70 pixel to account for header and tab navigator
            window.scrollTo({ top: rootRef.current.offsetTop - 70 });
        }

        function onMobileMapViewSwitchChange() {
            removeQueryParameters(WizardQueryParameterKeys.MOBILE_DISPLAY_MAP);
        }
        //#endregion
        //#endregion

        //#region Render Functions
        function getNeighborhoodCard(neighborhood: NeighborhoodDTO, isShortCard?: boolean) {
            const propertiesInNeighborhood = getNeighborhoodProperties(neighborhood);
            const { minPrice, propertyCount } = getNeighborhoodStats(neighborhood);
            const onHover = isMobile ? undefined : onNeighborhoodCardHoverAndLeave;

            return (
                <NeighborhoodCard
                    key={neighborhood.name}
                    isShortCard={isShortCard}
                    neighborhood={neighborhood}
                    properties={propertiesInNeighborhood}
                    propertyCount={propertyCount}
                    minPrice={minPrice}
                    onHoverAndLeaveHandler={onHover}
                />
            );
        }

        //#region Card Lists
        function getPropertyCardList() {
            if (inBoundsProperties.length === 0 && outOfBoundsProperties.length === 0) {
                return <CardListNoResults />;
            }

            const inBoundProperties = isMobile ? sortedFilteredProperties : inBoundsProperties;
            const outOfBoundProperties = isMobile ? [] : outOfBoundsProperties;
            const selectedProperty = isMobile ? undefined : propertyWithSelectedBorder;

            return (
                <PropertyCardList
                    ref={cardListRef}
                    className={styles.cardContainer}
                    displayFloorPlan={displayPropertyFloorPlan}
                    inBoundProperties={inBoundProperties}
                    outOfBoundProperties={outOfBoundProperties}
                    isMobile={isMobile}
                    selectedProperty={selectedProperty}
                    pagination={pagination}
                    onHoverAndLeaveHandler={onPropertyCardHoverAndLeave}
                />
            );
        }

        function getFloorPlanCardList() {
            if (filteredSortedFloorPlans.length === 0) {
                return <CardListNoResults />;
            }

            const isOwnersLand = parameters[WizardQueryParameterKeys.OWNERS_LAND] as boolean | undefined;
            const isVertical = floorPlanCardsAreVertical || isMobile;

            const floorPlanCards = filteredSortedFloorPlans.map((floorPlan, index) => (
                <FloorPlanCard
                    key={index}
                    floorPlan={floorPlan}
                    isOwnersLand={isOwnersLand}
                    isVertical={isVertical}
                    onClick={onFloorPlanCardClickHandler}
                    onHoverAndLeaveHandler={onFloorPlanCardHoverAndLeave}
                />
            ));

            return <CardList className={styles.cardContainer} cards={floorPlanCards} pagination={pagination} usesPageScroll={isMobile} />;
        }

        function getNeighborhoodCardList() {
            if (sortedFilteredNeighborhoods.length === 0) {
                return <CardListNoResults />;
            }

            if (isMobile) {
                // On Mobile show all neighborhoods cards in this list since map is separate tab
                const neighborhoodCards = sortedFilteredNeighborhoods.map((neighborhood) => getNeighborhoodCard(neighborhood));

                return (
                    <CardList
                        className={styles.cardContainer}
                        cards={neighborhoodCards}
                        divider={<CardListDivider text="NEIGHBORHOODS OUTSIDE THE MAP" />}
                        pagination={pagination}
                        usesPageScroll={true}
                    />
                );
            }

            const inBoundNeighborhoodCards: ReactElement[] = inBoundNeighborhoods.map((neighborhood) => getNeighborhoodCard(neighborhood));
            const outOfBoundNeighborhoodCards: ReactElement[] = outOfBoundNeighborhoods.map((neighborhood) =>
                getNeighborhoodCard(neighborhood)
            );

            return (
                <CardList
                    className={styles.cardContainer}
                    cards={inBoundNeighborhoodCards}
                    cardsAfterDivider={outOfBoundNeighborhoodCards}
                    divider={<CardListDivider text="NEIGHBORHOODS OUTSIDE THE MAP" />}
                    pagination={pagination}
                />
            );
        }

        function getCardList() {
            if (isLoading) {
                return <LoadingSpinner />;
            }

            if (showNeighborhoodCards) {
                return getNeighborhoodCardList();
            }

            if (showFloorPlanCards) {
                return getFloorPlanCardList();
            }

            return getPropertyCardList();
        }
        //#endregion

        //#region Mobile Carousel
        function getPlaceholderCard(title: string) {
            const buttonChildren = (
                <>
                    <LocationPinIcon className={styles.locationPinIcon} /> Recenter
                </>
            );

            return (
                <PlaceholderCard
                    className={styles.placeholderCard}
                    key="placeholderCard"
                    subText={"Recenter the map to continue your search!"}
                    title={title}
                    buttonChildren={buttonChildren}
                    onButtonClick={onRecenterClickHandler}
                />
            );
        }

        function getCarouselNeighborhoodCards() {
            if (inBoundNeighborhoods.length === 0) {
                return [getPlaceholderCard("Neighborhoods Outside The Map")];
            }

            return inBoundNeighborhoods.map((neighborhood) => getNeighborhoodCard(neighborhood, true));
        }

        function getCarouselPropertyCards() {
            if (inBoundsProperties.length === 0) {
                return [getPlaceholderCard("Homes Outside The Map")];
            }

            return inBoundsProperties.map((property, index) => {
                // Only need display state if it is selected property to give it the construction status border
                let displayState = undefined;
                if (property.streetAddress === propertyWithSelectedBorder?.streetAddress) {
                    displayState = CardDisplayState.SELECTED;
                }

                return <PropertyCard key={index} property={property} isShortCard={true} displayState={displayState} />;
            });
        }

        function getCarouselCards() {
            if (showNeighborhoodCards) {
                return getCarouselNeighborhoodCards();
            }

            return getCarouselPropertyCards();
        }

        function getMobileMapCarousel() {
            const cards = getCarouselCards();

            // If we are only showing one card don't do the mobileMapViewCarouselBeforeChange and don't render infinite carousel
            if (cards.length === 1) {
                return <div className={styles.mobileMapCardContainer}>{cards}</div>;
            }

            if (mobileCarouselIndex === -1) {
                mobileMapViewCarouselBeforeChange(0, 0);
            }

            return (
                <BaseCarousel
                    key={cards.length}
                    className={styles.mobileMapCardContainer}
                    infinite={true}
                    numberOfSlidesToPreload={3}
                    showOverflow={true}
                    slideGap={16}
                    currentSlide={mobileCarouselIndex}
                    beforeChange={mobileMapViewCarouselBeforeChange}
                >
                    {cards}
                </BaseCarousel>
            );
        }
        //#endregion
        //#endregion

        //#region Utility functions
        function getNeighborhoodProperties(neighborhood: NeighborhoodDTO): PropertyDTO[] {
            if (!properties) {
                return [];
            }

            const propertiesInNeighborhood: PropertyDTO[] = [];
            properties.forEach((property) => {
                if (property.neighborhood?.name !== neighborhood.name) {
                    return;
                }

                propertiesInNeighborhood.push(property);
            });

            return propertiesInNeighborhood;
        }

        function getNeighborhoodStats(neighborhood: NeighborhoodDTO): { minPrice: number | undefined; propertyCount: number } {
            let propertyCount = 0;
            let minPrice: number | undefined = undefined;

            // From the filtered properties get the min price and property count for this neighborhood
            sortedFilteredProperties.forEach((property) => {
                if (property.neighborhood?.name !== neighborhood.name) {
                    return;
                }

                propertyCount++;
                if (property.priceRange && (!minPrice || property.priceRange.min < minPrice)) {
                    minPrice = property.priceRange.min;
                }

                if (property.price && (!minPrice || property.price < minPrice)) {
                    minPrice = property.price;
                }
            });

            return { minPrice, propertyCount };
        }

        function getMapStartingPerimeter() {
            // Get the maps perimeter by fitting the bounds of all neighborhoods combined
            const combinedPerimeter: number[][] = [];
            sortedFilteredNeighborhoods.forEach((neighborhood) => {
                if (neighborhood.location?.perimeter) {
                    combinedPerimeter.push(...neighborhood.location.perimeter);
                }
            });

            if (combinedPerimeter.length === 0) {
                return;
            }

            return combinedPerimeter;
        }
        //#endregion

        const showNeighborhoodCards = isOnNeighborhoodsPath(location.pathname) || isOnAmenitiesPath(location.pathname);
        const showPropertiesOnMap = !showNeighborhoodCards;
        const showPropertyAndFloorPlanToggle = isOnBrowsePath(location.pathname);
        const classes = classNames(styles.root, className);

        if (isMobile) {
            const listIconColor = onListViewMobile ? "var(--executive-blues-80)" : "var(--secondary-90)";
            const mapIconColor = onListViewMobile ? "var(--secondary-90)" : "var(--executive-blues-80)";
            const listIcon = <ListIcon className={styles.listIcon} strokeColor={listIconColor} fillColor={listIconColor} />;
            const mapIcon = <MapIcon className={styles.mapIcon} strokeColor={mapIconColor} />;
            const listViewListOption = <div className={styles.viewSelectorContentWrapper}>{listIcon} List View</div>;
            const listViewMapOption = <div className={styles.viewSelectorContentWrapper}>{mapIcon} Map View</div>;

            // Use hidden style instead of conditional render to allow google map to initialize before the map tab is selected
            const mobileListViewClasses = classNames(styles.mobileListView, !onListViewMobile && styles.hidden);
            const mobileListViewSwitchClasses = classNames(styles.listSwitch, mobileListViewSwitchClassName);
            const mobileMapViewClasses = classNames(styles.mobileMapView, onListViewMobile && styles.hidden, mobileMapWrapperClassName);
            const mobileSearchBarWrapperClasses = classNames(styles.mobileSearchBarWrapper, mobileSearchBarWrapperClassName);

            return (
                <div ref={rootRef} className={classes}>
                    {/*Used to not zoom on mobile when input is clicked*/}
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
                    <div className={mobileSearchBarWrapperClasses}>
                        <SearchInputWithFilter
                            isFilterActive={isPropertyFilterPanelActive(propertyFilter)}
                            placeHolder={filterByType}
                            floorPlans={floorPlans}
                            neighborhoods={neighborhoods}
                            properties={properties}
                            propertyListController={propertyListController}
                            schoolDistricts={schoolDistricts}
                            searchFilterType={filterByType}
                            hideSort={showNeighborhoodCards}
                        />
                    </div>

                    <div className={mobileListViewClasses}>
                        <MultiStateSwitch
                            className={mobileListViewSwitchClasses}
                            options={[listViewListOption, listViewMapOption]}
                            selectedPosition={onListViewMobile ? 0 : 1}
                            switchStyle={MultiStateSwitchStyle.FILTER}
                            onChange={onMobileListViewSwitchChange}
                        />
                        {getCardList()}
                    </div>

                    <div className={mobileMapViewClasses}>
                        <GoogleMaps
                            ref={googleMapsRef}
                            className={styles.mapContainer}
                            neighborhoods={sortedFilteredNeighborhoods}
                            properties={sortedFilteredProperties}
                            setMapBounds={setMapBoundsFilter}
                            onPropertyClick={onPropertyClickMapHandler}
                            showProperties={showPropertiesOnMap}
                            startingSelectedProperty={inBoundsProperties[0]}
                            keepPropertySelectedOnMapClick={true}
                            keyboardShortcuts={false}
                            perimeter={mapPerimeter}
                            {...mapProps}
                        />
                        <MultiStateSwitch
                            className={styles.mapSwitch}
                            options={[listIcon, mapIcon]}
                            selectedPosition={onListViewMobile ? 0 : 1}
                            switchStyle={MultiStateSwitchStyle.FILTER}
                            onChange={onMobileMapViewSwitchChange}
                        />
                        {getMobileMapCarousel()}
                    </div>
                </div>
            );
        }

        const leftContainerClasses = classNames(styles.leftContainer, showNeighborhoodCards && styles.padTop);

        return (
            <div ref={rootRef} className={classes}>
                <div ref={leftContainerRef} className={leftContainerClasses}>
                    <div className={styles.switchAndSearchContainer}>
                        {showPropertyAndFloorPlanToggle && (
                            <MultiStateSwitch
                                buttonClassName={styles.multiStateSwitchButtons}
                                options={MULTI_STATE_SWITCH_OPTIONS}
                                onChange={onFloorPlanSwitchToggle}
                                selectedPosition={getFloorPlanSwitchInitialSelectedPosition()}
                            />
                        )}
                        <div className={styles.searchRow}>
                            <SearchInputWithFilter
                                isFilterActive={isPropertyFilterPanelActive(propertyFilter)}
                                placeHolder={filterByType}
                                floorPlans={floorPlans}
                                neighborhoods={neighborhoods}
                                properties={properties}
                                propertyListController={propertyListController}
                                schoolDistricts={schoolDistricts}
                                searchFilterType={filterByType}
                                hideSort={showNeighborhoodCards}
                            />
                        </div>
                    </div>
                    {getCardList()}
                </div>
                <GoogleMaps
                    ref={googleMapsRef}
                    className={styles.mapContainer}
                    neighborhoods={sortedFilteredNeighborhoods}
                    properties={sortedFilteredProperties}
                    showProperties={showPropertiesOnMap}
                    setMapBounds={setMapBoundsFilter}
                    onPropertyClick={onPropertyClickMapHandler}
                    perimeter={mapPerimeter}
                    {...mapProps}
                />
            </div>
        );
    }
);
