import { useEffect, useMemo, useState } from "react";

import { OpenHouseDTO } from "@executivehomes/eh-website-api";

import { useOpenHouses } from "../../../hooks/data/useOpenHouses";
import { useHeaderTheme } from "../../../hooks/useHeaderTheme";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { getDayOfWeekByDate, getDayOfWeekIndexByDate } from "../../../utilities/dates/getDayOfWeek";
import { getHourWithAMPM } from "../../../utilities/dates/getHourWithAMPM.ts";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { DayOfTheWeek } from "../../../utilities/enums/DayOfTheWeek";
import { OpenHouseCard } from "../../cards/open-house-card";
import { OpenHouseClosedCard } from "../../cards/open-house-closed-card";
import { BaseCarousel } from "../../carousels/base-carousel";
import { MultiStateSwitch, MultiStateSwitchStyle } from "../../inputs/multi-state-switch";

import styles from "./open-house-menu-panel.module.scss";
import classNames from "classnames";

function getDayOfWeekIndexFromScheduleOccurrence(openHouseScheduleOccurrence: OpenHouseDTO): number {
    const startDate = new Date(openHouseScheduleOccurrence.startUtc);
    return getDayOfWeekIndexByDate(startDate);
}

export type OpenHouseMenuPanelProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export function OpenHouseMenuPanel({ className }: OpenHouseMenuPanelProps) {
    const [selectedDayOfWeekIndex, setSelectedDayOfWeekIndex] = useState<number>();

    const { screenWidth } = useScreenSize();
    const { openHouses } = useOpenHouses();
    const { isHeaderWhite } = useHeaderTheme();

    /**
     * Once we have open houses, set the active index to the first open house schedule in the list
     */
    useEffect(() => {
        if (!openHouses || openHouses.length === 0) {
            return;
        }

        // Set the first button to selected
        setSelectedDayOfWeekIndex(getDayOfWeekIndexFromScheduleOccurrence(openHouses[0]));
    }, [openHouses]);

    //#region useMemos
    /**
     * Gets the text for the clickable buttons on the day selector for the open house carousel
     */
    const multiStateSwitchOptions = useMemo(() => {
        let needsSaturday = true;
        let needsSunday = true;
        const options = openHouses.map((occurrence) => {
            const startTime = new Date(occurrence.startUtc);
            const dayOfWeek = getDayOfWeekByDate(startTime);

            if (dayOfWeek === DayOfTheWeek.SATURDAY) {
                needsSaturday = false;
            } else if (dayOfWeek === DayOfTheWeek.SUNDAY) {
                needsSunday = false;
            }

            if (occurrence.startUtc && occurrence.endUtc) {
                const endTime = new Date(occurrence.endUtc);

                let parsedStartTime = getHourWithAMPM(startTime);
                const parsedEndTime = getHourWithAMPM(endTime);

                if (parsedStartTime.slice(-2) === parsedEndTime.slice(-2)) {
                    parsedStartTime = parsedStartTime.slice(0, -2);
                }

                return `${dayOfWeek} ${parsedStartTime}-${parsedEndTime}`;
            }

            return dayOfWeek;
        });

        if (needsSaturday) {
            options.push("Saturday");
        }

        if (needsSunday) {
            options.push("Sunday");
        }

        return options;
    }, [openHouses]);

    /**
     * Get the properties that are used in the open house carousel
     */
    const properties = useMemo(() => {
        const openHouseScheduleOccurrence = openHouses.find(
            (occurrence) => getDayOfWeekIndexFromScheduleOccurrence(occurrence) === selectedDayOfWeekIndex
        );

        if (!openHouseScheduleOccurrence) {
            return undefined;
        }

        return openHouseScheduleOccurrence.properties;
    }, [openHouses, selectedDayOfWeekIndex]);
    //#endregion

    /**
     * Get what carousel block we want to show depending on the properties we have
     * If we have no properties, show the Closed for the day slide
     * If we have properties, show them for that specific selected day
     */
    function getCarousel() {
        if (!properties) {
            return (
                <div className={styles.openHouseClosedCardWrapper}>
                    <OpenHouseClosedCard title="Closed for the day" description="Check back next week!" isWhite={isWhiteHeader} />
                </div>
            );
        }

        const carouselClasses = classNames(styles.openHouseCarousel, isHeaderWhite() && styles.lightDots);

        return (
            <BaseCarousel key={selectedDayOfWeekIndex} className={carouselClasses} arrows={isDesktop} dots={true}>
                {properties.map((property, index) => (
                    <OpenHouseCard key={index} property={property} />
                ))}
            </BaseCarousel>
        );
    }

    /**
     * What to do on the click of the buttons within the multi-state switch (This is the days of the week + times for the open house carousel)
     * @param index number  A number 0 - N length of the openHouses array
     */
    function onClickMultiStateSwitchButton(index: number) {
        setSelectedDayOfWeekIndex(getDayOfWeekIndexFromScheduleOccurrence(openHouses[index]));
    }

    const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;
    const isWhiteHeader = isHeaderWhite();
    const switchStyle = isWhiteHeader ? MultiStateSwitchStyle.SEPARATED_LIGHT : MultiStateSwitchStyle.SEPARATED_DARK;
    const classes = classNames(styles.root, isWhiteHeader && styles.darkText, className);

    return (
        <div className={classes}>
            <div className={styles.panelTitle}>OPEN HOUSES</div>
            <MultiStateSwitch
                className={styles.switch}
                options={multiStateSwitchOptions}
                switchStyle={switchStyle}
                onChange={onClickMultiStateSwitchButton}
            />
            {getCarousel()}
        </div>
    );
}
