import { ReactElement, forwardRef, useMemo, useState } from "react";

import { StyleDTO } from "@executivehomes/eh-website-api";

import { useDragDetection } from "../../../hooks/useDragDetection";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { getIconForStyleCategory } from "../../../utilities/mappers/icons/getIconForStyleCategory";
import { StyleComparisonCard } from "../../cards/style-comparison-card";
import { BaseCarousel } from "../../carousels/base-carousel";
import { IconTabCarousel } from "../../carousels/icon-tab-carousel";
import { HorizontalSectionHeader } from "../../headers/horizontal-section-header";
import { MobileBlockHeader } from "../../headers/mobile-block-header";
import { IconTab } from "../../tabs/icon-tab";

import styles from "./styles-comparison-block.module.scss";
import classNames from "classnames";

export type StylesComparisonBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The styles to compare
     */
    styleObjects?: StyleDTO[];
    /**
     * The function to select a style
     */
    onStyleSelect?: (styleName: string) => void;
};

export const StylesComparisonBlock = forwardRef(
    ({ className, styleObjects = [], onStyleSelect }: StylesComparisonBlockProps, ref: React.ForwardedRef<HTMLDivElement>) => {
        const [selectedTabTitle, setSelectedTabTitle] = useState<string>();

        const { dragging, onMouseDownCapture } = useDragDetection();
        const { screenWidth } = useScreenSize();

        const commonCategoryNames = useMemo(() => {
            if (styleObjects.length < 2 || !styleObjects[0].categories) {
                return [];
            }

            // Extract the category names from the first style
            const firstStyleCategories = styleObjects[0].categories.map((obj) => obj.name);
            const stylesWithoutFirst = styleObjects.slice(1);

            // Reduce the categories to get the intersection of category names with the other styles
            const commonCategoryNames = stylesWithoutFirst.reduce((commonCategories, currentList) => {
                if (!currentList.categories) {
                    return [];
                }

                const currentCategoryNames = currentList.categories.map((category) => category.name);
                const filteredCommonCategories = commonCategories.filter((categoryName) => currentCategoryNames.includes(categoryName));

                return filteredCommonCategories;
            }, firstStyleCategories);

            const firstCategoryName = commonCategoryNames[0];
            if (firstCategoryName) {
                // Set selected tab title to first index by default when styles changes
                setSelectedTabTitle(firstCategoryName);
            }

            return commonCategoryNames;
        }, [styleObjects]);

        function onStyleComparisonCardClick(selectedStyleName: string) {
            if (dragging || !onStyleSelect) {
                return;
            }

            onStyleSelect(selectedStyleName);
        }

        //#region Render functions
        function getSectionHeader() {
            if (isMobile) {
                return;
            }

            return (
                <HorizontalSectionHeader
                    title={getSectionHeaderTitle()}
                    subtitle="Each style is loaded with premium finishes that have been carefully chosen and refined to complement that style. For the best value, we recommend sticking with one style but we do offer the ability to mix and match certain features."
                />
            );
        }

        function getSectionHeaderTitle() {
            return "Compare Styles";
        }

        function getStyleCompareSlides() {
            // Get the current categories images for each style and store it along with the style name
            const styleSelectedCategoryAttachments = styleObjects.map((styleObject) => {
                const selectedCategory = styleObject.categories?.find((category) => selectedTabTitle === category.name);

                return { name: styleObject.name, attachments: selectedCategory?.images ?? [] };
            });

            if (styleSelectedCategoryAttachments.length === 0) {
                return;
            }

            const compareSlides: ReactElement[] = [];

            // Get the length of the shortest attachment array
            const minAttachmentLength = Math.min(
                ...styleSelectedCategoryAttachments.map((selectedCategoryStyle) => selectedCategoryStyle.attachments.length)
            );

            // Loop through the list of attachments for the selected category up to the length of the lowest style attachment length
            // to map all styles to a single comparison slide with their respective attachment at each index
            for (let attachmentIndex = 0; attachmentIndex < minAttachmentLength; attachmentIndex++) {
                const styleImageCards: ReactElement[] = [];

                // Loop through the styles to add each styles attachment at the current index to the current comparison slide
                for (let styleIndex = 0; styleIndex < styleSelectedCategoryAttachments.length; styleIndex++) {
                    const attachments = styleSelectedCategoryAttachments[styleIndex].attachments;
                    const onStyleComparisonCard = onStyleSelect ? onStyleComparisonCardClick : undefined;

                    // Add this styles card to the comparison slide
                    styleImageCards.push(
                        <StyleComparisonCard
                            key={`${attachmentIndex}-${styleIndex}`}
                            styleName={styleSelectedCategoryAttachments[styleIndex].name}
                            attachment={attachments[attachmentIndex]}
                            onStyleSelect={onStyleComparisonCard}
                            onMouseDownCapture={onMouseDownCapture}
                        />
                    );
                }

                // Add this comparison slide to the carousel slide array
                compareSlides.push(
                    <div key={attachmentIndex} className={styles.compareSlide}>
                        {styleImageCards}
                    </div>
                );
            }

            return compareSlides;
        }

        function getStylesComparisonCarousel() {
            // Use bigger slider gap on desktop in order to not hide box shadow overflow but also keep outer ones off screen
            const slideGap = isMobile ? 16 : 48;

            return (
                <BaseCarousel
                    key={selectedTabTitle}
                    className={styles.carousel}
                    slideGap={slideGap}
                    controls={!isMobile}
                    dots={isMobile}
                    showOverflow={true}
                >
                    {getStyleCompareSlides()}
                </BaseCarousel>
            );
        }

        function getIconTabs() {
            const tabs: ReactElement[] = [];

            commonCategoryNames.forEach((categoryName, index) => {
                const isSelected = selectedTabTitle === categoryName;
                tabs.push(
                    <IconTab
                        key={index}
                        name={categoryName}
                        icon={getIconForStyleCategory(categoryName)}
                        isSelected={isSelected}
                        onClick={() => setSelectedTabTitle(categoryName)}
                    />
                );
            });

            return tabs;
        }
        //#endregion

        const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;

        const classes = classNames(styles.root, className);

        return (
            <section ref={ref} className={classes}>
                <MobileBlockHeader title="Compare" />
                <div className={styles.stickyWrapper}>
                    {getSectionHeader()}
                    <IconTabCarousel>{getIconTabs()}</IconTabCarousel>
                </div>
                {getStylesComparisonCarousel()}
            </section>
        );
    }
);
