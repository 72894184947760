import { ReactNode, useRef, useState } from "react";

import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../../hooks/useScreenSize";
import { Constants } from "../../../../utilities/Constants";
import { HorizontalBreakpoint } from "../../../../utilities/enums/Breakpoints";
import { BaseCarousel } from "../../../carousels/base-carousel";
import { CarouselArrowStyle } from "../../../carousels/base-carousel/base-carousel";
import { InformationIcon } from "../../../icons/information-icon";
import { Modal } from "../../../modals/modal";

import styles from "./entity-card-content.module.scss";
import classNames from "classnames";

export enum EntityCardLayout {
    FULL,
    LEFT_HALF,
    TOP_HALF,
    SHORT,
}

export type EntityCardContentProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether to use the dark gradient version
     */
    darkGradient?: boolean;
    /**
     * The layout of the content
     */
    layout?: EntityCardLayout;
    /**
     * Content to put in the top left box of the card
     */
    topLeftContent?: ReactNode;
    /**
     *  Content to put in the top right box of the card
     */
    topRightContent?: ReactNode;
    /**
     * Content you want to display above the title
     */
    preTitleContent?: ReactNode;
    /**
     * Title of the card to display
     */
    title?: ReactNode;
    /**
     * The first subtitle to display under the title of the card
     */
    firstSubtitle?: string;
    /**
     * The second subtitle to display under the first subtitle of the card
     */
    secondSubtitle?: string;
    /**
     * Images to put in a carousel on the card
     */
    images?: AttachmentDTO[];
    /**
     * Array of react nodes to display in the boxes at the bottom of the card
     */
    bottomRowContent?: ReactNode[];
    /**
     * The details block to display inside of the popup modal
     * when pressing the information button on the card
     */
    detailsBlockInModal?: ReactNode;
    /**
     * Whether to open the attached modal when the content is clicked
     */
    openModalOnClick?: boolean;
    /**
     * Whether to show the swipeable hint animation on the carousel
     */
    showSwipeableHintAnimation?: boolean;
    /**
     * Event to call when carousel is expanded or retracted
     */
    onCarouselSizeChange?: (hideMoreDetails: boolean) => void;
};

export function EntityCardContent({
    className,
    darkGradient,
    layout = EntityCardLayout.FULL,
    topLeftContent,
    topRightContent,
    preTitleContent,
    title = "",
    firstSubtitle,
    secondSubtitle,
    images = [],
    bottomRowContent = [],
    detailsBlockInModal,
    openModalOnClick,
    showSwipeableHintAnimation,
    onCarouselSizeChange,
}: EntityCardContentProps) {
    const expandedViewTimeout = useRef<NodeJS.Timeout>();

    const [detailsBlockModalIsOpen, setDetailsBlockModalOpen] = useState<boolean>(false);
    const [expandCarouselView, setExpandCarouselView] = useState<boolean>(false);
    const [showArrows, setShowArrows] = useState<boolean>(false);

    const { screenWidth } = useScreenSize();

    function onInformationButtonPress(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        event.preventDefault();
        setDetailsBlockModalOpen(true);
    }

    function onCloseDetailsModal() {
        setDetailsBlockModalOpen(false);
    }

    function changeCarouselSize(hideMoreDetails: boolean) {
        setExpandCarouselView(hideMoreDetails);
        if (onCarouselSizeChange) {
            onCarouselSizeChange(hideMoreDetails);
        }
    }

    function onCarouselChange() {
        if (expandedViewTimeout) {
            clearTimeout(expandedViewTimeout.current);
        }
        changeCarouselSize(true);
        setShowArrows(true);

        expandedViewTimeout.current = setTimeout(() => {
            changeCarouselSize(false);
            setShowArrows(false);
        }, 5000);
    }

    //#region Render Functions
    function getInformationButton() {
        if (!detailsBlockInModal || isMobile) {
            return;
        }

        return (
            <button className={styles.informationButton} onClick={onInformationButtonPress}>
                <InformationIcon className={styles.informationIcon} />
            </button>
        );
    }

    function getTopRowContent() {
        return (
            <div className={styles.top}>
                {topLeftContent && topRightContent && (
                    <div className={styles.infoGroup}>
                        <div className={styles.info}>{topLeftContent}</div>
                        <div className={styles.info}>{topRightContent}</div>
                    </div>
                )}
                {getInformationButton()}
            </div>
        );
    }

    function getImageRegion() {
        const shouldHaveCarousel = images.length > 1;

        if (!shouldHaveCarousel || layout === EntityCardLayout.SHORT) {
            return <img src={images[0].url} className={styles.imageInSlide} />;
        }

        return (
            <BaseCarousel
                className={styles.imageCarousel}
                arrowsClassName={styles.carouselArrows}
                slideNumberClassName={styles.carouselSlideNumber}
                arrowsStyle={CarouselArrowStyle.INTERIOR_ARROWS}
                arrows={!isMobile}
                draggable={isMobile}
                fade={!isMobile}
                beforeChange={onCarouselChange}
                showSlideNumber={true}
                showSwipeableHintAnimation={showSwipeableHintAnimation}
            >
                {images.map((image, index) => {
                    const isScaleDown = image.title === Constants.SCALE_DOWN_IMAGE_TITLE;
                    const imageClasses = classNames(styles.imageInSlide, isScaleDown && styles.scaleDown);
                    return <img key={index} src={image.url} className={imageClasses} />;
                })}
            </BaseCarousel>
        );
    }

    function getSubtitleSection() {
        if (firstSubtitle) {
            if (secondSubtitle) {
                return (
                    <>
                        <div className={styles.firstSubtitle}>{firstSubtitle}</div>
                        <div className={styles.secondSubtitle}>{secondSubtitle}</div>
                    </>
                );
            }

            return <div className={styles.subtitleSolo}>{firstSubtitle}</div>;
        }

        if (secondSubtitle) {
            return <div className={styles.secondSubtitle}>{secondSubtitle}</div>;
        }
    }

    function getBottomRowContent() {
        if (bottomRowContent.length < 1) {
            return;
        }

        return (
            <div className={styles.infoGroup}>
                {bottomRowContent.map((content, index) => (
                    <div key={index} className={styles.infoBlock}>
                        {content}
                    </div>
                ))}
            </div>
        );
    }
    //#endregion

    function getClassByEntityCardLayout() {
        if (layout === EntityCardLayout.FULL) {
            return;
        }

        if (layout === EntityCardLayout.TOP_HALF) {
            return styles.singleColumnCard;
        }

        if (layout === EntityCardLayout.SHORT) {
            return styles.shortCard;
        }

        if (layout === EntityCardLayout.LEFT_HALF) {
            return styles.leftHalfCard;
        }
    }

    function getHoverProps() {
        if (isMobile) {
            return;
        }

        // Only give onMouseEnter/Leave on desktop or else mobile will treat first tap as onMouseEnter
        // Making onClick feel laggy since it takes two clicks often
        return {
            onMouseEnter: () => setShowArrows(true),
            onMouseLeave: () => setShowArrows(false),
        };
    }

    function onCardContentClick() {
        if (!openModalOnClick) {
            return;
        }

        setDetailsBlockModalOpen(true);
    }

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
    const classes = classNames(
        styles.root,
        darkGradient && styles.darkGradient,
        getClassByEntityCardLayout(),
        showArrows && styles.showArrows,
        expandCarouselView && styles.expandedCarouselView,
        className
    );

    return (
        <div className={classes} onClick={onCardContentClick} {...getHoverProps()}>
            <div className={styles.carouselWrapper}>
                {images && images.length > 0 && getImageRegion()}
                <div className={styles.gradientOnCarousel} />
            </div>
            {getTopRowContent()}

            <div className={styles.bottom}>
                {preTitleContent && <div className={styles.preTitleContentWrapper}>{preTitleContent}</div>}
                <div className={styles.titleDiv}>{title}</div>
                {getSubtitleSection()}
                {getBottomRowContent()}
            </div>

            <Modal className={styles.modalWrapper} isOpen={detailsBlockModalIsOpen} onCloseModal={onCloseDetailsModal}>
                {detailsBlockInModal}
            </Modal>
        </div>
    );
}
