import { useRef, useState } from "react";

import { useObserveElement } from "../../../hooks/useObserveElement";
import { Orientation } from "../../../utilities/enums/Orientation";
import { WarrantyCard } from "../../cards/warranty-card/warranty-card";

import styles from "./warranty-block.module.scss";
import classNames from "classnames";

export type WarrantyBlockProps = {
    /**
     * The style passed from the parent
     */
    className?: string;
    /**
     * Whether the image is on the left or the
     */
    orientation?: Orientation.LEFT | Orientation.RIGHT;
    /**
     * The url of the image to display
     */
    imageSrc?: string;
    /**
     * The title
     */
    titleText?: string;
    /**
     * The description of the title
     */
    contentText?: string;
};

export function WarrantyBlock({ className, orientation = Orientation.LEFT, imageSrc, titleText, contentText }: WarrantyBlockProps) {
    const divRef = useRef<HTMLDivElement>(null);
    const [isInViewport, setIsInViewport] = useState<boolean>();

    useObserveElement({
        element: divRef.current,
        onEnterViewport: () => setIsInViewport(true),
    });

    const classes = classNames(
        styles.root,
        isInViewport && styles.active,
        orientation === Orientation.LEFT ? styles.left : styles.right,
        className
    );

    return (
        <div ref={divRef} className={classes}>
            <div className={styles.imageDiv}>
                <img className={styles.image} src={imageSrc} />
            </div>
            <WarrantyCard className={styles.card} titleText={titleText} contentText={contentText} />
        </div>
    );
}
