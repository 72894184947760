import { Orientation } from "../../../utilities/enums/Orientation";
import { ArrowIcon } from "../arrow-icon";
import { DollarSignIcon } from "../dollar-sign-icon";

import styles from "./price-sort-icon.module.scss";
import classNames from "classnames";

export type PriceSortIconProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the arrow is pointed up or down indicating an ascending or descending sort order
     */
    isAscending?: boolean;
};

export function PriceSortIcon({ isAscending = false, className }: PriceSortIconProps) {
    const arrowDirection = isAscending ? Orientation.UP : Orientation.DOWN;

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <DollarSignIcon className={styles.dollarSignIcon} />
            <ArrowIcon className={styles.arrowIcon} arrowDirection={arrowDirection} />
        </div>
    );
}
