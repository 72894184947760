import { useMemo, useRef, useState } from "react";

import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { CatalogItemsBlock } from "../../components/blocks/catalog-item-block";
import { DetailsBlock } from "../../components/blocks/details-blocks/details-block";
import { TabPair } from "../../components/blocks/details-blocks/details-block/details-block";
import { BaseButton, ButtonStyle } from "../../components/buttons/base-button";
import { DetailsBlockAttachmentCarousel } from "../../components/carousels/details-block-attachment-carousel";
import { FeaturesAndUpgradesPagePreFooter } from "../../components/footers/features-and-upgrades-page-pre-footer";
import { MobilePropertySummaryFooter } from "../../components/footers/mobile-property-summary-footer";
import { HorizontalSectionHeader } from "../../components/headers/horizontal-section-header";
import { PropertySummaryHeader } from "../../components/headers/property-summary-header";
import { MobileBlockSeparator } from "../../components/misc/mobile-block-separator";
import { MobilePageTabNavigator } from "../../components/misc/mobile-page-tab-navigator";
import { NavigationTab } from "../../components/misc/mobile-page-tab-navigator/mobile-page-tab-navigator";
import { PropertyInlineInfo } from "../../components/misc/property-inline-info";
import { usePropertyFlow } from "../../hooks/usePropertyFlow";
import { WizardQueryParameterKeys, useQueryParameters } from "../../hooks/useQueryParameters";
import { useScreenSize } from "../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../utilities/enums/Breakpoints";
import { Orientation } from "../../utilities/enums/Orientation";
import { getCombinedQueryParameters } from "../../utilities/urls/getCombinedQueryParameters";
import { getPropertyUrl } from "../../utilities/urls/getPropertyUrl";
import { Page } from "../page";

import styles from "./features-and-upgrades-page.module.scss";
import classNames from "classnames";

import availableSelectionsImage from "../../assets/pages/features-and-upgrades-page/AvailableSelectionsImage.jpg";
import backsplashImage from "../../assets/pages/features-and-upgrades-page/BacksplashImage.jpg";
import countertopsImage from "../../assets/pages/features-and-upgrades-page/CountertopsImage.jpg";
import distinctiveDetailsImage from "../../assets/pages/features-and-upgrades-page/DistinctiveDetailsImage.jpg";
import frontDoorImage from "../../assets/pages/features-and-upgrades-page/FrontDoorImage.jpg";
import lightFixturesImage from "../../assets/pages/features-and-upgrades-page/LightFixturesImage.jpg";
import luxuryIsIncludedImage from "../../assets/pages/features-and-upgrades-page/LuxuryIsIncludedImage.jpg";
import makeItYoursImage from "../../assets/pages/features-and-upgrades-page/MakeItYoursImage.jpg";
import masonryImage from "../../assets/pages/features-and-upgrades-page/MasonryImage.jpg";
import paintImage from "../../assets/pages/features-and-upgrades-page/PaintImage.jpg";
import premiumIsOurStandardImage from "../../assets/pages/features-and-upgrades-page/PremiumIsOurStandardImage.jpg";
import upgradesTabFifthImage from "../../assets/pages/features-and-upgrades-page/UpgradesTabFifthImage.jpg";
import upgradesTabFirstImage from "../../assets/pages/features-and-upgrades-page/UpgradesTabFirstImage.jpg";
import upgradesTabFourthImage from "../../assets/pages/features-and-upgrades-page/UpgradesTabFourthImage.jpg";
import upgradesTabSecondImage from "../../assets/pages/features-and-upgrades-page/UpgradesTabSecondImage.jpg";
import upgradesTabSeventhImage from "../../assets/pages/features-and-upgrades-page/UpgradesTabSeventhImage.jpg";
import upgradesTabSixthImage from "../../assets/pages/features-and-upgrades-page/UpgradesTabSixthImage.jpg";
import upgradesTabThirdImage from "../../assets/pages/features-and-upgrades-page/UpgradesTabThirdImage.jpg";
import woodFloorsImage from "../../assets/pages/features-and-upgrades-page/WoodFloorsImage.jpg";

const includedTabImages: AttachmentDTO[] = [
    {
        title: "PREMIUM IS OUR STANDARD",
        description: "Every home is loaded with high-end finishes that most builders consider to be costly upgrades.",
        url: premiumIsOurStandardImage,
    },
    {
        title: "LUXURY IS INCLUDED",
        description: "Unexpected luxuries, such as the included pot filler of the cooktop, are included at an unbeatable value!",
        url: luxuryIsIncludedImage,
    },
    {
        title: "DISTINCTIVE DETAILS",
        description: "The small details combine with the premium, guaranteed quality to produce homes that stand the test of time.",
        url: distinctiveDetailsImage,
    },
];

const upgradesTabImages: AttachmentDTO[] = [
    {
        title: "CUSTOMIZE YOUR HOME",
        description:
            "With your home already loaded with premium features, the 100+ upgrades on our website are designed for you to personalize your home and have some fun!",
        url: upgradesTabFirstImage,
    },
    {
        title: "CUSTOMIZE YOUR HOME",
        description:
            "With your home already loaded with premium features, the 100+ upgrades on our website are designed for you to personalize your home and have some fun!",
        url: upgradesTabSecondImage,
    },
    {
        title: "CUSTOMIZE YOUR HOME",
        description:
            "With your home already loaded with premium features, the 100+ upgrades on our website are designed for you to personalize your home and have some fun!",
        url: upgradesTabThirdImage,
    },
    {
        title: "CUSTOMIZE YOUR HOME",
        description:
            "With your home already loaded with premium features, the 100+ upgrades on our website are designed for you to personalize your home and have some fun!",
        url: upgradesTabFourthImage,
    },
    {
        title: "CUSTOMIZE YOUR HOME",
        description:
            "With your home already loaded with premium features, the 100+ upgrades on our website are designed for you to personalize your home and have some fun!",
        url: upgradesTabFifthImage,
    },
    {
        title: "CUSTOMIZE YOUR HOME",
        description:
            "With your home already loaded with premium features, the 100+ upgrades on our website are designed for you to personalize your home and have some fun!",
        url: upgradesTabSixthImage,
    },
    {
        title: "CUSTOMIZE YOUR HOME",
        description:
            "With your home already loaded with premium features, the 100+ upgrades on our website are designed for you to personalize your home and have some fun!",
        url: upgradesTabSeventhImage,
    },
];

const selectedTabImages: AttachmentDTO[] = [
    {
        title: "MAKE IT YOURS",
        description:
            "The personalized selections, such as masonry, countertops, and light fixtures, are chosen after Purchase Agreement signing. So no rush!",
        url: makeItYoursImage,
    },
    {
        title: "AVAILABLE SELECTIONS",
        description:
            "The available selections depend on the earnest money option. Scroll for more info and contact us for specific details for your home!",
        url: availableSelectionsImage,
    },
    {
        title: "COUNTERTOPS",
        description:
            "We have more than 20 granite, marble, dolomite, and quartz options to choose from! With the exception of one upgrade group, every countertop option is included at no additional cost!",
        url: countertopsImage,
    },
    {
        title: "BACKSPLASH",
        description:
            "Complement your countertops with one of several backsplash choices. It's a great way to add personality to the kitchen!",
        url: backsplashImage,
    },
    {
        title: "LIGHT FIXTURES",
        description:
            "You'll have an opportunity to meet with our lighting vendor to pick your light fixtures. They can source essentially anything to match your style.",
        url: lightFixturesImage,
    },
    {
        title: "PAINT",
        description:
            "Stick with one of our time-tested combinations for a guaranteed home run or choose unique colors to match your personal style.",
        url: paintImage,
    },
    {
        title: "WOOD FLOORS",
        description:
            "Our standard wood floor finishes have been tailored to perfectly complement our two styles. For you prefer something different, we offer the option to personalize your stain.",
        url: woodFloorsImage,
    },
    {
        title: "MASONRY",
        description: "Our diverse brick and stone options are a great way to personalize your curb appeal.",
        url: masonryImage,
    },
    {
        title: "FRONT DOOR",
        description: "Premium wood and iron front door options are available to personalize your entryway.",
        url: frontDoorImage,
    },
];

export function FeaturesAndUpgradesPage() {
    const includedFeaturesRef = useRef<HTMLDivElement>(null);
    const optionalUpgradesRef = useRef<HTMLDivElement>(null);

    const [isPropertySummaryHeaderVisible, setPropertySummaryHeaderVisible] = useState<boolean>(false);
    const [isOptionalUpgradesButtonVisible, setOptionalUpgradesButtonVisible] = useState<boolean>(true);

    const { screenWidth } = useScreenSize();
    const { parameters } = useQueryParameters();
    const { property, catalogItems } = usePropertyFlow();

    const propertyPageUrl = useMemo(() => {
        let queryParameters = "";

        const ownersLand = parameters[WizardQueryParameterKeys.OWNERS_LAND] as boolean | undefined;
        if (ownersLand) {
            const ownersLandQueryParameter = `${WizardQueryParameterKeys.OWNERS_LAND}=true`;
            queryParameters = ownersLandQueryParameter;
        }

        const floorPlanName = parameters[WizardQueryParameterKeys.FLOOR_PLAN] as string | undefined;
        const styleName = parameters[WizardQueryParameterKeys.STYLE] as string | undefined;

        if (!floorPlanName) {
            return;
        }

        const floorPlanQueryParameter = `${WizardQueryParameterKeys.FLOOR_PLAN}=${encodeURIComponent(floorPlanName)}`;
        const styleQueryParameter = `${WizardQueryParameterKeys.STYLE}=${styleName}`;
        queryParameters = getCombinedQueryParameters(queryParameters, floorPlanQueryParameter, styleQueryParameter);

        // If we have upgrades add it to the query parameters for the summary page
        const upgradeIds = parameters[WizardQueryParameterKeys.UPGRADES] as string | undefined;
        if (upgradeIds) {
            const upgradesQueryParameter = `${WizardQueryParameterKeys.UPGRADES}=${upgradeIds}`;
            queryParameters = getCombinedQueryParameters(queryParameters, upgradesQueryParameter);
        }

        // If owners land pass undefined
        const propertyToPass = ownersLand ? undefined : property;
        const propertyUrl = getPropertyUrl(propertyToPass);
        const url = `${propertyUrl}?${queryParameters}`;

        return url;
    }, [
        property,
        parameters[WizardQueryParameterKeys.FLOOR_PLAN],
        parameters[WizardQueryParameterKeys.STYLE],
        parameters[WizardQueryParameterKeys.UPGRADES],
    ]);

    //#region Util Functions
    function checkScrollY() {
        if (!optionalUpgradesRef.current) {
            return;
        }

        // Check if the container scroll position is greater than 0
        const scrolled = window.scrollY > 0;
        // Update property header visibility based on the scroll position
        setPropertySummaryHeaderVisible(scrolled);

        // Check if current scroll is on optional upgrades block to hide button that scrolls there
        if (window.scrollY < optionalUpgradesRef.current?.offsetTop) {
            setOptionalUpgradesButtonVisible(true);
            return;
        }

        setOptionalUpgradesButtonVisible(false);
    }

    function scrollToOptionalUpgrades() {
        if (!optionalUpgradesRef.current) {
            return;
        }

        window.scrollTo({ top: optionalUpgradesRef.current.offsetTop - 50, behavior: "smooth" });
    }
    //#endregion

    //#region Render Functions
    function getMobileTabNavigator() {
        const navigationTabs: NavigationTab[] = [];

        if (includedFeaturesRef.current) {
            navigationTabs.push({ blockElement: includedFeaturesRef.current, name: "Included Features" });
        }

        if (optionalUpgradesRef.current) {
            navigationTabs.push({ blockElement: optionalUpgradesRef.current, name: "Optional Upgrades" });
        }

        return <MobilePageTabNavigator navigationTabs={navigationTabs} />;
    }

    function getAdditionalHeader() {
        if (isDesktop) {
            return <PropertySummaryHeader buttons={getHeaderButtons()} isHidden={!isPropertySummaryHeaderVisible} property={property} />;
        }

        return getMobileTabNavigator();
    }

    function getHeaderButtons() {
        const optionalUpgradesButtonClasses = classNames(styles.optionalUpgradesButton, !isOptionalUpgradesButtonVisible && styles.hidden);

        return (
            <>
                <BaseButton
                    className={optionalUpgradesButtonClasses}
                    buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                    disabled={!isOptionalUpgradesButtonVisible}
                    onClick={scrollToOptionalUpgrades}
                >
                    View Upgrades
                </BaseButton>
                <BaseButton href={propertyPageUrl}>View Summary</BaseButton>
            </>
        );
    }

    function getIncludedTab(): TabPair {
        const tabTitle = isDesktop ? "Included Features" : "Included";
        const tabContent = <DetailsBlockAttachmentCarousel attachments={includedTabImages} />;

        return {
            tabTitle,
            tabContent,
        };
    }

    function getUpgradesTab(): TabPair {
        const tabTitle = isDesktop ? "Optional Upgrades" : "Upgrades";
        const tabContent = <DetailsBlockAttachmentCarousel attachments={upgradesTabImages} />;

        return {
            tabTitle,
            tabContent,
        };
    }

    function getSelectionsTab(): TabPair {
        const tabTitle = isDesktop ? "Other Selections" : "Selections";
        const tabContent = <DetailsBlockAttachmentCarousel attachments={selectedTabImages} />;

        return {
            tabTitle,
            tabContent,
        };
    }

    function getDetailsBlockTabs(): TabPair[] {
        const includedTab = getIncludedTab();
        const upgradesTab = getUpgradesTab();
        const selectionsTab = getSelectionsTab();

        return [includedTab, upgradesTab, selectionsTab];
    }

    function getBottomButtonSection() {
        return (
            <div className={styles.detailsBlockButtonsWrapper}>
                {isOptionalUpgradesButtonVisible && (
                    <BaseButton
                        className={styles.baseButton}
                        chevronArrowDirection={Orientation.DOWN}
                        hasChevron={isDesktop}
                        onClick={scrollToOptionalUpgrades}
                    >
                        View Upgrades
                    </BaseButton>
                )}
                <BaseButton
                    className={styles.baseButton}
                    buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                    hasChevron={isDesktop}
                    href={propertyPageUrl}
                >
                    View Summary
                </BaseButton>
            </div>
        );
    }

    function getFeaturesAndUpgradesPreFooter() {
        return <FeaturesAndUpgradesPagePreFooter propertyPageUrl={propertyPageUrl} />;
    }

    function getMobileStickyFooter() {
        if (isDesktop) {
            return;
        }

        return <MobilePropertySummaryFooter property={property} bottomSection={getBottomButtonSection()} />;
    }
    //#endregion

    // If no floorPlan and style yet, assume we are still fetching from the api so do not return anything to prevent placeholder flickers
    if (!property.floorPlan || !property.style) {
        return null;
    }

    const isDesktop = screenWidth > HorizontalBreakpoint.MEDIUM;

    return (
        <Page className={styles.root} onPageScroll={checkScrollY} preFooterContent={getFeaturesAndUpgradesPreFooter()}>
            {!isDesktop && <HorizontalSectionHeader title="Features & Upgrades" />}
            {getAdditionalHeader()}
            {isDesktop && (
                <DetailsBlock
                    title="features & upgrades"
                    description={<PropertyInlineInfo property={property} />}
                    tabs={getDetailsBlockTabs()}
                    bottomSection={getBottomButtonSection()}
                />
            )}
            <CatalogItemsBlock ref={includedFeaturesRef} catalogItems={catalogItems?.includedFeatures} hasMobileHeader={false} />
            <MobileBlockSeparator />
            <CatalogItemsBlock
                ref={optionalUpgradesRef}
                catalogItems={catalogItems?.upgrades}
                isUpgradeBlock={true}
                selectedUpgrades={property.selectedUpgrades}
            />
            {getMobileStickyFooter()}
        </Page>
    );
}
