import { useEffect, useMemo, useRef, useState } from "react";

import { useLocation } from "react-router-dom";

import { useMarket } from "../../../hooks/data/useMarket";
import { useMarkets } from "../../../hooks/data/useMarkets";
import { useHeaderTheme } from "../../../hooks/useHeaderTheme";
import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { Orientation } from "../../../utilities/enums/Orientation";
import { isOnNeighborhoodPath } from "../../../utilities/routing/utils/isOnNeighborhoodPath";
import { isOnPropertyPath } from "../../../utilities/routing/utils/isOnPropertyPath";
import { ChevronIcon } from "../../icons/chevron-icon";
import { LocationPinIcon } from "../../icons/location-pin-icon";

import styles from "./market-select-drop-down.module.scss";
import classNames from "classnames";

export type MarketSelectDropDownProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export function MarketSelectDropDown({ className }: MarketSelectDropDownProps) {
    const rootRef = useRef<HTMLDivElement>(null);

    const [isOpen, setOpen] = useState<boolean>(false);

    const { markets, didError: didMarketsError } = useMarkets();
    const { getSelectedMarket, setSelectedMarket } = useMarket();
    const marketName = getSelectedMarket(false);
    const { isHeaderWhite } = useHeaderTheme();
    const { parameters } = useQueryParameters();
    const { pathname } = useLocation();

    const formattedLowerCaseMarketName = useMemo(() => {
        if (marketName) {
            return marketName.replace("-", " ").toLowerCase();
        }
    }, [marketName]);

    useEffect(() => {
        // Set up on blur to happen if anywhere not within the parent or its children is clicked
        document.addEventListener("mousedown", onDropDownBlur);

        return () => {
            document.removeEventListener("mousedown", onDropDownBlur);
        };
    }, []);

    function onDropDownBlur(event: MouseEvent) {
        if (!rootRef.current) {
            return;
        }

        const targetElement = event.target as Element;

        // If target is not in panel and isnt the parents filter button close the panel
        if (!rootRef.current.contains(targetElement)) {
            setOpen(false);
        }
    }

    function onMarketSelect(marketName: string) {
        setSelectedMarket(marketName);
        setOpen(false);
    }

    function getMarketButtons() {
        const marketButtons = markets.map(({ name }, index) => {
            const isSelected = name.toLowerCase() === formattedLowerCaseMarketName;
            const buttonClasses = classNames(styles.optionButton, isSelected && styles.selected);

            return (
                <button key={index} className={buttonClasses} onClick={() => onMarketSelect(name)}>
                    <LocationPinIcon className={styles.locationPin} />
                    {name}
                </button>
            );
        });

        return marketButtons;
    }

    function getDropdownDisabled() {
        // Disable the drop down if we are on property page, neighborhood page, or have a property selected
        return isOnPropertyPath(pathname) || isOnNeighborhoodPath(pathname) || !!parameters[WizardQueryParameterKeys.PROPERTY];
    }

    if (didMarketsError) {
        return null;
    }

    const selectedText = formattedLowerCaseMarketName ?? "Choose City";
    const arrowDirection = isOpen ? Orientation.UP : Orientation.DOWN;
    const isWhiteHeader = isHeaderWhite();
    const isMarketDropdownDisabled = getDropdownDisabled();

    const classes = classNames(styles.root, isWhiteHeader && styles.white, className);

    if (isMarketDropdownDisabled) {
        return (
            <div ref={rootRef} className={classes}>
                <div className={styles.pinTextWrapper}>
                    <LocationPinIcon className={styles.locationPin} />
                    {selectedText}
                </div>
            </div>
        );
    }

    const buttonClasses = classNames(styles.pinTextWrapper, styles.pointerCursor);
    return (
        <div ref={rootRef} className={classes}>
            <button className={buttonClasses} onClick={() => setOpen(!isOpen)}>
                <LocationPinIcon className={styles.locationPin} />
                {selectedText}
                <ChevronIcon className={styles.chevronIcon} arrowDirection={arrowDirection} />
            </button>
            {isOpen && <div className={styles.dropDown}>{getMarketButtons()}</div>}
        </div>
    );
}
